import './Footer.css';
import ArrowDownIcon from '../assets/arrow-down.svg';
import UsFlagIcon from '../assets/us-flag.svg';

const footerLinks = ['About', 'Privacy Policy', 'Help', 'Terms & Conditions', 'Cookies',  'Contact'];

interface FooterProps {
  classNameProp?: string;
}

function Footer({classNameProp = ''}: FooterProps) {
  return (
    <footer data-testid="footer" className={`${classNameProp}footer`}>
      <div className={`${classNameProp}footer-links`}>
        {footerLinks.map(footerLink => (
          <button key={footerLink} className={`${classNameProp}footer-link`}>{footerLink}</button>
        ))}
      </div>
      <div className={`${classNameProp}language-container`}>
        <img 
          alt="flag icon"
          src={UsFlagIcon}
          className="arrow-icon"
        />
        English
        <img 
          alt="arrow down"
          src={ArrowDownIcon}
          className="arrow-icon"
        />
      </div>
    </footer>
  );
}

export default Footer;