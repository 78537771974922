const fileTypes = Object.freeze({
  STUDENT_ROSTER: 'STUDENT_ROSTER',
  COURSE_CATALOG: 'COURSE_CATALOG',
  STUDENT_DEMOGRAPHICS: 'STUDENT_DEMOGRAPHICS',
  STUDENT_TRANSCRIPTS: 'TRANSCRIPT',
  TEST_SCORES: 'STUDENT_TEST_SCORES',
  CTE_PATHWAYS: 'CTE_PATHWAY_CATALOG'
});

const SCHOOL_LOCAL_STORAGE_KEY = 'school';
const DISTRICT_LOCAL_STORAGE_KEY = 'district';

const AUDIT_LOG_ITEMS_PER_PAGE = 10;
const DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE = 10;

const HIGH_FLIERS_SELECTION_LIIMIT = 2;
const ON_THE_CUSP_SELECTION_LIIMIT = 4;
const STRUGGLING_SELECTION_LIIMIT = 2;


export {
  fileTypes,
  SCHOOL_LOCAL_STORAGE_KEY,
  DISTRICT_LOCAL_STORAGE_KEY,
  AUDIT_LOG_ITEMS_PER_PAGE,
  DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE,
  HIGH_FLIERS_SELECTION_LIIMIT,
  ON_THE_CUSP_SELECTION_LIIMIT,
  STRUGGLING_SELECTION_LIIMIT
};