import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import CustomModal from './CustomModal';
import UploadIcon from '../assets/data-upload.svg';
import FileUploadStatus from './FileUploadStatus';
import {UploadedFileData} from '../../interfaces/UploadedFileDataInterface';
import { fileTypes } from '../../constants';
import FileTypesValues from '../../interfaces/FileType';
import UploadPrompt from '../data upload/upload prompts/UploadPrompt';
import OpenModalIconButton from './OpenModalIconButton';

import './UploadCard.css';

interface UploadCardProps {
  title: string;
  helperText: string[];
  uploadedFilesData: UploadedFileData[];
  modalTitle: string;
  modalData: React.ReactNode;
  fileType: FileTypesValues;
}

function UploadCard({ title, helperText, modalTitle, uploadedFilesData, modalData, fileType }: UploadCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<File[]>([]);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const fileIdToReplace = useRef<string | undefined>(undefined);

  const handleDrop = (file: any) => {
    setFile(file);
    setIsPromptOpen(true);
  };

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({ 
    multiple: false, 
    maxFiles: 1, 
    onDrop: handleDrop, 
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    }
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleClosePrompt = () => {
    setIsPromptOpen(false);
    fileIdToReplace.current = undefined;
  };

  const handleResubmit = (currentFileId: string) => {
    open();
    fileIdToReplace.current = currentFileId;
  };

  return (
    <div className="upload-card">
      <div className="upload-card-header">
        <p>{title}</p>
        <OpenModalIconButton handleOpen={handleOpenModal} buttonLabel="What to upload" />
      </div>
      <div className="upload-card-helper-text">
        {helperText.map((text, index) => <p key={index}>{text}</p>)}
      </div>
      <div className="upload-card-content">
        <div data-testid="drop-zone" className={`upload-card-drop-zone ${uploadedFilesData && uploadedFilesData.length > 0 ? 'half-width' : ''}`}>
          <section>
            <div {...getRootProps()} className={`drop-zone ${isDragAccept ? 'drag-active' : ''}`}>
              <input data-testid="drop-zone-file-input" {...getInputProps()} />
              <p className="drop-zone-text">All .csv, .xls, .xlsx file types are supported</p>
              <img src={UploadIcon} alt="upload file icon" className="upload-icon"></img>
              <p className="drop-zone-text">Drag files here to upload</p>
              <p className="drop-zone-text link-color">or browse for files</p>
            </div>
          </section>
          <div className="separator">
            <span className="separator-text">or</span>
          </div>
          <button className="sign-in-button" disabled>Sign in via SIS</button>
        </div>
        { uploadedFilesData && uploadedFilesData.length > 0 ?
          <div className="uploaded-files">
            {uploadedFilesData.map((fileStatusData, index) => (
              <FileUploadStatus
                key={index}
                fileId={fileStatusData.file_id}
                fileName={fileStatusData.file_name}
                fileSize="32mb"
                fileStatus={fileStatusData.status}
                results={fileStatusData.results_summary}
                statistics={fileStatusData.statistics}
                onResubmit={handleResubmit}
              />
            ))}
          </div>
          :
          null
        }
      </div>
      {/* what to upload modal */}
      {isModalOpen &&
        <CustomModal 
          title={modalTitle}
          isOpen={isModalOpen} 
          handleClose={handleCloseModal} 
          icon={true}
          sx={{'& .MuiDialogContent-root': {
            backgroundColor: 'var(--container-background-color)'
          }}}
          dividers={true}
        >
          {modalData}
        </CustomModal>
      }

      {/* upload prompt modal */}
      { file.length > 0 ?
        <UploadPrompt
          isOpen={isPromptOpen}
          handleClose={handleClosePrompt}
          fileName={file[0].name}
          fileType={fileType}
          file={file}
          isRosterUpload={fileType === fileTypes.STUDENT_ROSTER}
          fileIdToReplace={fileIdToReplace.current}
        />
        :
        null
      }
    </div>
  );
}

export default UploadCard;