import GreenCheckBox from './reusable/GreenCheckBox';
import EmptyCheckBox from './reusable/EmptyCheckBox';
import ErrorStatusIcon from './reusable/ErrorStatusIcon';
import Loader from './reusable/Loader';
import { AxiosResponse } from 'axios';
import { UploadRequirementsTable } from '../interfaces/UploadedFileDataInterface';

import './SchoolsTable.css';

interface SchoolTableProps {
  isPending: boolean;
  error: unknown;
  response: AxiosResponse<UploadRequirementsTable> | null
}

function SchoolTable({ isPending, error, response }: SchoolTableProps) {
  
  if (isPending) return <Loader />;
  
  if (error || !response) return <p>Missing schools data!</p>;

  const data = response.data;

  const prepareData = () => {
    let columnNames: string[] = [];
    let tableData: (string | boolean)[][] = [];
    
    Object.entries(data.schools).forEach(([schoolName, schoolData]) => {
      columnNames = [...Object.keys(schoolData)];
      tableData.push([schoolName, ...Object.values(schoolData)]);
    });

    return {columnNames, tableData};
  };
  
  const {columnNames, tableData} = prepareData();
  
  return (
    <>
      <div className="table-container">
        <table className="table">
          <thead className="table-header">
            <tr className="table-row">
              <td colSpan={10} className="table-data"></td>
              {columnNames.map((columnName, index) => {
                return ( 
                  <td key={index} colSpan={10} className="table-data-element">{columnName}</td>
                );
              })}
            </tr>
          </thead>
          <tbody className="table-body">
            {tableData.map((dataRow, index) => (
              <tr key={index} className="table-row">
                {dataRow.map((dataCell, index) => {
                  const isLastElement = index === dataRow.length - 1;
                  return (
                    index === 0 ?
                      <td
                        key={index}
                        colSpan={10}
                        className={`table-data-element ${isLastElement ? 'table-data-last-element' : ''}`}
                      >
                        {dataCell}
                      </td>
                      :
                      <td
                        key={index}
                        colSpan={10}
                        className={`table-data-element ${isLastElement ? 'table-data-last-element' : ''}`}
                      >
                        {dataCell === true ? <GreenCheckBox /> : null}
                        {dataCell === false ? <EmptyCheckBox /> : null}
                        {dataCell === 'error' ? <ErrorStatusIcon /> : null}
                      </td>
                  );
                })}
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </>
   
  );
}

export default SchoolTable;