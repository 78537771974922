import { Transcript, Category } from '../../interfaces/TranscriptsInterface';
import { getTranscriptSelectionSummary } from '../../services/transcriptsService';
import useFetchData from '../hooks/useFetchData';
import { useMemo, useState, useRef } from 'react';
import useSchools from '../hooks/useSchools';
import { darkGreenBarColor, lightGreenBarColor } from '../../theme/xq-custom-theme';
import { createChartConfigs } from './utilityFunctions';
import TranscriptSelectionHeader from './TranscriptSelectionHeader';
import TranscriptSelectionTables from './TranscriptSelectionTables';
import TranscriptSelectionSideDrawer from './TranscriptSelectionSideDrawer';
import TranscriptSelectionWarningModal from './TranscriptSelectionWarningModal';
import TranscriptSelectionContext from '../../context/TranscriptSelectionContext';
import Loader from '../reusable/Loader';
import { HIGH_FLIERS_SELECTION_LIIMIT, ON_THE_CUSP_SELECTION_LIIMIT, STRUGGLING_SELECTION_LIIMIT } from '../../constants';

import './TranscriptSelection.css';

const categories: Category[] = [
  { name: 'HIGH_FLIER', label: 'High fliers' },
  { name: 'ON_THE_CUSP', label: 'On the cusp' },
  { name: 'STRUGGLING', label: 'Struggling' }
];

const selectionLimits = [HIGH_FLIERS_SELECTION_LIIMIT, ON_THE_CUSP_SELECTION_LIIMIT, STRUGGLING_SELECTION_LIIMIT];

function TranscriptSelection() {
  const { selectedSchoolFromSideNav } = useSchools();

  const [refreshData, setRefreshData] = useState(false);

  const { isPending, error, response } = useFetchData(
    {
      fetchData: (eoaId: string, access_token: string) => getTranscriptSelectionSummary(eoaId, access_token, selectedSchoolFromSideNav), 
      errorMessage: 'Error occured while fetching transcript selection summary data!', 
      options: {
        dependencies: [selectedSchoolFromSideNav, refreshData]
      }
    }
  );

  const [selectedTranscripts, setSelectedTranscripts] = useState<Transcript[]>([]);

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const memoizedCategories = useMemo(() => {
    if (!response) return null;
    let data = response.data;
    data.is_finalized = true; // REMOVE AFTER XQ REVIEW

    setIsSideDrawerOpen(data.is_finalized);
    
    return categories.map(({ name, label }, index) => {
      const filteredData = data.transcripts.filter(transcript => transcript.category === name);
      return {
        name,
        label,
        filteredData,
        selectionLimit: selectionLimits[index]
      };
    });
  }, [response]);

  const memoizedDataForCharts = useMemo(() => {
    if (!response) return null;
    let data = response.data;
    data.is_finalized = true; // REMOVE AFTER XQ REVIEW

    return createChartConfigs(data.transcripts, darkGreenBarColor);
  }, [response]);

  const memoizedSelectionChartData = useMemo(() => {
    if (!selectedTranscripts.length) return null;

    return createChartConfigs(selectedTranscripts, lightGreenBarColor);
  }, [selectedTranscripts]);

  const temporarySelection = useRef<Transcript[]>([]);

  if (isPending) return <Loader />;
  
  if (error || !response) return <p>Missing transcript selection data!</p>;

  const data = response.data;
  data.is_finalized = true; // REMOVE AFTER XQ REVIEW

  // REMOVE isSelected PARAMETER AFTER XQ REVIEW
  const handleSelectTranscripts = (newTranscriptSelection: Transcript[], isSelected: boolean) => {
    if (data.is_finalized && isSelected) {
      temporarySelection.current = newTranscriptSelection;
      setIsModalOpen(true);
    } else {
      setSelectedTranscripts(newTranscriptSelection);
    }
  };

  const handleCancelSelectionChange = () => {
    temporarySelection.current = [];
    setIsModalOpen(false);
  };

  const handleConfirmSelectionChange = () => {
    handleSelectTranscripts(temporarySelection.current, false);
    temporarySelection.current = [];
    setIsModalOpen(false);
  };

  return (
    <TranscriptSelectionContext.Provider value={{ selectedTranscripts, handleSelectTranscripts }}>
      <TranscriptSelectionHeader 
        isFinalized={data.is_finalized} 
        selectedSchool={selectedSchoolFromSideNav}
        triggerRefresh={() => setRefreshData(!refreshData)}
        handleViewSchoolDemographics={() => setIsSideDrawerOpen(true)}
      />
      <div className="transcript-selection-tables-charts-container">
        <TranscriptSelectionTables 
          isFinalized={data.is_finalized}
          categories={memoizedCategories}
        />
        <TranscriptSelectionSideDrawer 
          isOpen={isSideDrawerOpen}
          handleClose={() => setIsSideDrawerOpen(false)}
          chartsData={memoizedDataForCharts}
          selectionChartsData={memoizedSelectionChartData}
        />
      </div>
      <TranscriptSelectionWarningModal 
        isOpen={isModalOpen}
        handleConfirm={handleConfirmSelectionChange}
        handleCancel={handleCancelSelectionChange}
      />
    </TranscriptSelectionContext.Provider>
  );
}

export default TranscriptSelection;