import VerticalBarChart from '../../reusable/VerticalBarChart';
import HorizontalBarChart from '../../reusable/HorizontalBarChart';
import useFetchData from '../../hooks/useFetchData';
import { getTranscriptsDetails } from '../../../services/dataQcDetailsService';
import CardContainer from '../CardContainer';
import { darkGreenBarColor } from '../../../theme/xq-custom-theme';
import Loader from '../../reusable/Loader';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';

function TranscriptsSideDrawerContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response} = useFetchData({
    fetchData: (eoaId, access_token) => getTranscriptsDetails(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching transcript details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing trascript details data!</p>;
  
  const parseData = () => {
    let gpaDistribution = null;
    let subjectCredits = null;

    if (response[0].status === 'fulfilled') {
      let _data = response[0].value.data as { scores: number[] };
      gpaDistribution = {
        labels: _data.scores.map(score => score.toString()),
        data: _data.scores
      };
    }

    if (response[1].status === 'fulfilled') {
      let _data = response[1].value.data as { [key: string]: number };

      subjectCredits = {
        labels: Object.keys(_data.subjects),
        data: Object.values(_data.subjects)
      };
    }

    return { gpaDistribution, subjectCredits };
  };

  const { gpaDistribution, subjectCredits } = parseData();

  return (
    <>
      <CardContainer title="Student GPA distribution" centerTitle={true}>
        {gpaDistribution ?
          <VerticalBarChart
            labels={gpaDistribution.labels}
            dataSets={[{data: gpaDistribution.data, backgroundColor: darkGreenBarColor}]}
            displayDataLabels={false}
          />
          :
          <p>Error occured while fetching "Student GPA distribution" data!</p>
        }
      </CardContainer>
      <CardContainer title="Average number of credits taken per subject area" centerTitle={true}>
        {subjectCredits ?
          <HorizontalBarChart dataValues={subjectCredits.data} labels={subjectCredits.labels} />
          :
          <p>Error occured while fetching "Average number of credits taken per subject area" data!</p>
        }
      </CardContainer>
    </>
  );
}

export default TranscriptsSideDrawerContent;