import { getCourseCatalogsSummary } from '../../../services/dataQcSummaryService';
import useFetchData from '../../hooks/useFetchData';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';

function CourseCatalogsContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getCourseCatalogsSummary(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage:'Error occured while fetching course catalog summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });
  
  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing course catalog summary data!</p>;

  const {course_catalog_count, total_course_count, course_counts} = response.data;

  return (
    <ul data-testid="course-catalog-list">
      <li>{course_catalog_count} course catalogs found</li>
      <li>
        {total_course_count} courses found
        <ul className="list-type-disc">
          {Object.entries(course_counts).map(([key, value]) => (
            <li key={key}>{value} {key}</li>
          ))}
        </ul>
      </li>
    </ul>
  );
}

export default CourseCatalogsContent;