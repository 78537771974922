import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowUpIcon from '../assets/arrow-up.svg';
import ArrowDownIcon from '../assets/arrow-down.svg';
import './SideBarNavigationLink.css';

interface SideBarNavigationLinkProps {
  path: string;
  icon: string;
  label: string;
  children: Array<{id: number, label: string, path: string}>;
  isExpandable: boolean;
  currentPath: string;
}

const SideBarNavigationLink = ({
  path, 
  icon, 
  label, 
  children, 
  isExpandable, 
  currentPath
} : SideBarNavigationLinkProps) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandSubLinks = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <li data-testid="link-container" className={`link-container ${currentPath.includes(path) ? 'active' : ''}`}>
        <img className="link-icon" alt="link icon" src={icon} />
        <Link className="link" to={path}>
          {label}
        </Link>
        {isExpandable ?
          <img 
            id={label}
            alt={isExpanded ? 'arrow up': 'arrow down'} 
            src={isExpanded ? ArrowUpIcon : ArrowDownIcon}
            className="arrow-icon"
            onClick={handleExpandSubLinks}
          />
          :
          null
        }
      </li>

      {isExpanded && (
        <li data-testid="sub-links-container" className="sub-links-container">
          <ul className="sub-links-list">
            {children.map(({id, label, path}) => (
              <li key={id}>
                <Link to={path} className={`link link-container ${currentPath.includes(path) ? 'active' : ''}`}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      )}
    </>
  );
};

export default SideBarNavigationLink;