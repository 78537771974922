import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Navigate
} from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import XQTheme from './theme/xq-custom-theme';
import DataUpload from './components/data upload/DataUpload';
import ProtectedRoute from './components/ProtectedRoute';
import Overview from './components/Overview';
import Documents from './components/help/Documents';
import Help from './components/help/Help';
import DataQcSummary from './components/data qc/DataQcSummary';
import MatchingContent from './components/data qc/matching/MatchingContent';
import TestScoresContent from './components/data qc/test scores/TestScoresContent';
import CourseCatalogsContent from './components/data qc/course catalog/CourseCatalogsContent';
import TranscriptsContent from './components/data qc/transcripts/TranscriptsContent';
import CteCoursesContent from './components/data qc/cte courses/CteCoursesContent';
import DataQcLayout from './components/data qc/DataQcLayout';
import DataQcContentContainer from './components/data qc/DataQcContentContainer';
import MatchingSideDrawerContent from './components/data qc/matching/MatchingSideDrawerContent';
import TestScoresDrawerContent from './components/data qc/test scores/TestScoresDrawerContent';
import TranscriptsSideDrawerContent from './components/data qc/transcripts/TranscriptsSideDrawerContent';
import CourseCatalogSideDrawerContent from './components/data qc/course catalog/CourseCatalogSideDrawerContent';
import CtePathwaysSideDrawerContent from './components/data qc/cte courses/CtePathwaysSideDrawerContent';
import TranscriptSelection from './components/transcripts/TranscriptSelection';
import Login from './components/login/LoginPage';
import GlobalStateContextProvider from './context/GlobalStateContextProvider';
import NavigationLayout from './components/navigation/NavigationLayout';
import ErrorModelContextProvider from './context/ErrorModelContextProvider';

const Auth0ProviderLayout = () => (
  <Auth0Provider
    domain = {process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      audience: process.env.REACT_APP_BASE_URL
    }}
  >
    <Outlet />
  </Auth0Provider>
);

const routes = [
  {path: '/overview', element: <Overview/>},
  {path: '/data-upload', element: <DataUpload/>},
  {path: '/data-qc', element: <DataQcLayout/>, children: [
    { path: '', element: <DataQcSummary /> },
    { path: '/data-qc/matching', element: (
      <DataQcContentContainer 
        drawerTitle="Matching" 
        cardTitle="Matching"
        cardContent={<MatchingContent />}
        drawerContent={<MatchingSideDrawerContent />}
      />
    ) },
    { path: '/data-qc/test-scores', element: (
      <DataQcContentContainer
        drawerTitle="Test scores"
        cardTitle="Test scores"
        cardContent={<TestScoresContent />}
        drawerContent={<TestScoresDrawerContent />}
      />
    )},
    { path: '/data-qc/course-catalogs', element: (
      <DataQcContentContainer
        drawerTitle="Course catalogs"
        cardTitle="Course catalogs"
        cardContent={<CourseCatalogsContent />}
        drawerContent={<CourseCatalogSideDrawerContent />}
      />
    )},
    { path: '/data-qc/transcripts', element: (
      <DataQcContentContainer
        drawerTitle="Transcripts"
        cardTitle="Transcripts"
        cardContent={<TranscriptsContent />}
        drawerContent={<TranscriptsSideDrawerContent />}
      />
    )},
    { path: '/data-qc/cte-courses', element: (
      <DataQcContentContainer
        drawerTitle="CTE Pathways"
        cardTitle="CTE courses"
        cardContent={<CteCoursesContent />}
        drawerContent={<CtePathwaysSideDrawerContent />}
      />
    )},
  ]},
  {path: '/transcripts', element: <TranscriptSelection/>},
  {path: '/documents', element: <Documents/>},
  {path: '/help', element: <Help />},
];

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Auth0ProviderLayout />}>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<ErrorModelContextProvider />}>
          <Route element={<GlobalStateContextProvider />}>
            <Route element={<NavigationLayout />}>
              {routes.map(({path, element, children}, index) => (
                <Route key={index} path={path} element={element}>
                  { children?.map(({path, element}, index) => (
                    <Route key={index} path={path} element={element} />
                  ))}
                </Route>
              ))}
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={'/login'} />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={XQTheme}>
    <RouterProvider router={router}/>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
