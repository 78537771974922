import OpenModalIcon from '../assets/open-modal.svg';
import './OpenModalIconButton.css';

interface OpenModalIconButtonProps {
    handleOpen: () => void;
    buttonLabel: string;
}

function OpenModalIconButton({handleOpen: handleOpenModal, buttonLabel}: OpenModalIconButtonProps) {

  return (
    <div className="open-modal-button" onClick={handleOpenModal}>
      <span className="button-label">{buttonLabel}</span>
      <img src={OpenModalIcon} alt="open modal icon"></img>
    </div>
  );
}

export default OpenModalIconButton;