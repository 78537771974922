import { createTheme } from '@mui/material/styles';

const accentColorBlue = '#1A56DB'; // primary-700
const accentColorBlueLight = '#C3ddfd'; // primary-200
const accentColorBlueDark = '#1E429F'; // primary-800
const contrastTextColor = '#FFFFFF';
export const darkGreenBarColor = '#489F55';
export const lightGreenBarColor = '#84E1BC';

const XQTheme = createTheme({
  palette: {
    primary: {
      main: accentColorBlue,
      light: accentColorBlueLight,
      dark: accentColorBlueDark,
      contrastText: contrastTextColor
    },
    secondary: {
      main: contrastTextColor,
      contrastText: accentColorBlue,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: 'var(--border-radius)'
        }
      }
    }
  }
});

export default XQTheme;