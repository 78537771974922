import GreenCheckBox from './reusable/GreenCheckBox';
import EmptyCheckBox from './reusable/EmptyCheckBox';
import ErrorStatusIcon from './reusable/ErrorStatusIcon';
import Loader from './reusable/Loader';
import { AxiosResponse } from 'axios';
import { UploadRequirementsList } from '../interfaces/UploadedFileDataInterface';

import './SchoolsList.css';

interface SchoolsListProps {
  isPending: boolean;
  error: unknown;
  response: AxiosResponse<UploadRequirementsList> | null;
}

function SchoolsList({ isPending, error, response }: SchoolsListProps) {

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing schools data!</p>;

  const data = response.data;

  return (
    <div className="schools-list-container">
      {Object.entries(data.schools).map(([schoolName, requirementMet], index) => (
        <div key={index} className="school-container">
          {requirementMet === true ? <GreenCheckBox /> : null}
          {requirementMet === false ? <EmptyCheckBox /> : null }
          {requirementMet === 'error' ? <ErrorStatusIcon /> : null}
          <span>{schoolName}</span>
        </div>
      ))}
    </div>
  );
}

export default SchoolsList;