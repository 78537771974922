import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

interface PrimaryButtonProps {
  buttonText: string;
  buttonAction: () => void;
  disabled?: boolean;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
}

const StyledPrimaryButton = styled(Button, { shouldForwardProp: () => true })(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  padding: 'var(--scaling-spacing-small) var(--scaling-spacing-large)',
  '&.Mui-disabled': {
    borderColor: 'var(--border-color)'
  }
}));

export default function PrimaryButton({ buttonText, buttonAction, disabled, icon, iconPosition }: PrimaryButtonProps) {
  return (
    <StyledPrimaryButton 
      onClick={buttonAction} 
      variant="contained"
      disabled={disabled}
      startIcon={iconPosition === 'start' ? icon : null}
      endIcon={iconPosition === 'end' ? icon : null}
    >
      {buttonText}
    </StyledPrimaryButton>
  );
}