import { getMatchingSummary } from '../../../services/dataQcSummaryService';
import useFetchData from '../../hooks/useFetchData';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';

function MatchingContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getMatchingSummary(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching matching summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing matching summary data!</p>;

  const {
    students_matched_percentage, 
    transcript_courses_matched_percentage, 
    test_scores_matched_to_students_percentage, 
    cte_courses_matched_percentage 
  } = response.data;

  return (
    <ul data-testid="matching-content">
      <li>{students_matched_percentage}% of students matched across demographics, transcripts, and test scores</li>
      <li>{transcript_courses_matched_percentage}% of transcript courses matched to course catalog courses</li>
      <li>{test_scores_matched_to_students_percentage}% of test scores matched to students</li>
      <li>{cte_courses_matched_percentage}% of CTE courses matched to course catalog courses</li>
    </ul>
  );
}

export default MatchingContent;