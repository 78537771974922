import { getCtePathwaysSummary } from '../../../services/dataQcSummaryService';
import useFetchData from '../../hooks/useFetchData';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';

function CteCoursesContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getCtePathwaysSummary(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching CTE pathways summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });
  
  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing CTE pathways summary data!</p>;

  const { cte_catalogs_count, cte_eligible_courses_count, cte_pathways_count } = response.data;

  return (
    <ul data-testid="cte-courses-list">
      <li>{cte_catalogs_count} CTE catalogs</li>
      <li>{cte_eligible_courses_count} classes eligible for CTE pathways</li>
      <li>{cte_pathways_count} CTE pathways</li>
    </ul>
  );
}

export default CteCoursesContent;