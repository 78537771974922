import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './SideBarNavigation.css';
import OverviewIcon from '../assets/overview.svg';
import DataUploadIcon from '../assets/data-upload.svg';
import DataQcIcon from '../assets/data-qc.svg';
import DocsIcon from '../assets/docs.svg';
import HelpIcon from '../assets/help.svg';
import TranscriptsIcon from '../assets/transcripts-icon.svg';
import SideBarNavigationLink from './SideBarNavigationLink';
import SelectDropdown from '../reusable/SelectDropdown';
import { SelectChangeEvent } from '@mui/material';
import useSchools from '../hooks/useSchools';
import { DISTRICT_LOCAL_STORAGE_KEY } from '../../constants';
import Loader from '../reusable/Loader';

const navLinks = [
  {label: 'Data Upload', path: '/data-upload', children: [], isExpandable: false, icon: DataUploadIcon}, 
  {
    label: 'Data QC',
    path: '/data-qc',
    children: [
      {id: 1, label: 'Matching', path: '/data-qc/matching'},
      {id: 2, label: 'Test Scores', path: '/data-qc/test-scores'},
      {id: 3, label: 'Course Catalogs', path: '/data-qc/course-catalogs'},
      {id: 4, label: 'Transcripts', path: '/data-qc/transcripts'},
      {id: 5, label: 'CTE Courses', path: '/data-qc/cte-courses'}
    ], 
    isExpandable: true,
    icon: DataQcIcon
  },
  { label: 'Transcripts', path: '/transcripts', children: [], isExpandable: false, icon: TranscriptsIcon }
];

const helperLinks = [
  {label: 'Docs', path: '/documents', children: [], isExpandable: false, icon: DocsIcon},
  {label: 'Help', path: '/help', children: [], isExpandable: false, icon: HelpIcon}
];

const SideBarNavigation = ({isClosed}: {isClosed: boolean}) => {
  const [selectedDistrict, setSelectedDistrict] = useState(localStorage.getItem(DISTRICT_LOCAL_STORAGE_KEY) || '');

  const location = useLocation();

  const { 
    schools: {
      isPending: isSchoolsPending, 
      error: schoolsError, 
      data: schools
    }, 
    selectedSchoolFromSideNav,
    updateSelectedSchool
  } = useSchools();

  const handleChangeDistrict = (event: SelectChangeEvent) => {
    let newDisctrictValue = event.target.value;

    localStorage.setItem(DISTRICT_LOCAL_STORAGE_KEY, newDisctrictValue);
    setSelectedDistrict(newDisctrictValue);
  };

  const handleChangeSchool = (event: SelectChangeEvent) => {
    updateSelectedSchool(event.target.value);
  };

  return (
    <nav data-testid="sidebar" className={`sidebar ${isClosed ? 'closed' : ''}`}>
      <ul className="sidebar-items">
        <SideBarNavigationLink
          key={'Overview'}
          path={'/overview'}
          icon={OverviewIcon}
          label={'Overview'}
          children={[]}
          isExpandable={false}
          currentPath={location.pathname}
        />

        <li className="helper-links-separator"></li>

        <li>
          <SelectDropdown 
            selectedValue={selectedDistrict}
            label="Select"
            text="District"
            items={[{ label: 'District 1', value: '1' }, { label: 'District 2', value: '2' }]} 
            handleChange={handleChangeDistrict}
            containerClasses={['navigation-filter']}
            formControlClasses={['navigation-filter-form-control no-min-width']}
            isClearable={false}
            size="small"
          />
        </li>

        { location.pathname === '/transcripts' ?
          <li>
            {isSchoolsPending ? <Loader /> : null}
            {schoolsError ? <p className="navigation-filter">Missing schools data!</p> : null}
            {schools ?
              <SelectDropdown
                selectedValue={selectedSchoolFromSideNav}
                label="Select"
                text="School"
                items={schools.map(school => {
                  return { label: school.name, value: school.id };
                })}
                handleChange={handleChangeSchool}
                containerClasses={['navigation-filter']}
                formControlClasses={['navigation-filter-form-control no-min-width']}
                isClearable={false}
                size="small"
              />
              :
              null
            }
          </li>
          :
          null
        }

        {selectedDistrict && selectedDistrict.length > 0 && navLinks.map(listItem => (
          <SideBarNavigationLink 
            key={listItem.label}
            path={listItem.path}
            icon={listItem.icon}
            label={listItem.label}
            children={listItem.children}
            isExpandable={listItem.isExpandable}
            currentPath={location.pathname}
          />
        ))}
  
        <li className="helper-links-separator"></li>

        {helperLinks.map(listItem => (
          <SideBarNavigationLink 
            key={listItem.label}
            path={listItem.path}
            icon={listItem.icon}
            label={listItem.label}
            children={listItem.children}
            isExpandable={listItem.isExpandable}
            currentPath={location.pathname}
          />
        ))}
      </ul>
    </nav>
  );
};

export default SideBarNavigation;
