import { useReducer, useEffect, Reducer } from 'react';
import { 
  getRostersUploadRequirements,
  getCourseCatalogUploadRequirements,
  getStudentDemographicsUploadRequirements,
  getStudentTranscriptsUploadRequirements,
  getTestScoresUploadRequirements,
  getCTEPathwaysUploadRequirements
} from '../../services/uploadRequirementsService';
import UploadCardContainer from '../reusable/UploadCardContainer';
import { getUploadResults } from '../../services/uploadService';
import {UploadedFileData} from '../../interfaces/UploadedFileDataInterface';
import { fileTypes } from '../../constants';
import UploadResultsContext from '../../context/UploadResultsContext';
import reducer from './dataUploadReducer';
import { DATA_UPLOAD_REDUCER_ACTIONS } from './dataUploadReducer';
import { Action, UploadResults } from '../../context/UploadResultsContext';
import useFetchData from '../hooks/useFetchData';
import Loader from '../reusable/Loader';
import PageHeader from '../reusable/PageHeader';

const DataUpload = () => {
  const { isPending, error, response } = useFetchData({
    fetchData: getUploadResults, 
    errorMessage: 'Error occured while fetching upload data!'
  });

  const [uploadResults, dispatch] = useReducer<Reducer<UploadResults, Action>>(reducer, {
    [fileTypes.STUDENT_ROSTER]: [],
    [fileTypes.COURSE_CATALOG]: [],
    [fileTypes.STUDENT_DEMOGRAPHICS]: [],
    [fileTypes.STUDENT_TRANSCRIPTS]: [],
    [fileTypes.TEST_SCORES]: [],
    [fileTypes.CTE_PATHWAYS]: []
  });

  useEffect(() => {
    if (response) {
      response.data.forEach((uploadResult: UploadedFileData) => {
        uploadResults[uploadResult.upload_file_type].push(uploadResult);
      });
      dispatch({ type: DATA_UPLOAD_REDUCER_ACTIONS.SET_UPLOAD_RESULTS, payload: uploadResults });
    }
  }, [response]);

  if (isPending) return <Loader />;

  if (error || !response) return <p>Error occured while fetching upload data!</p>;

  return (
    <main>
      <PageHeader title="Data Upload" />
      <UploadResultsContext.Provider value={{uploadResults, dispatch}}>
        { cardsData.map((cardData, index) => (
          <UploadCardContainer 
            key={index}
            cardTitle={cardData.cardTitle}
            cardHelperText={cardData.cardHelperText}
            displayTable={cardData.displayTable}
            fetchData={cardData.fetchData}
            modalTitle={cardData.modalTitle}
            uploadedFilesData={uploadResults[cardData.fileType]}
            fileType={cardData.fileType}
          />
        ))}
      </UploadResultsContext.Provider>
    </main>
  );
};

const cardsData = [
  {
    cardTitle:'Student roster',
    cardHelperText: [
      'Please upload student roster(s) for the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure the file(s) contain at minimum Student ID and school ID if the file contains data for multiple schools.'
    ],
    modalTitle: 'Rosters required',
    displayTable: false,
    fetchData: getRostersUploadRequirements,
    fileType: fileTypes.STUDENT_ROSTER
  },
  {
    cardTitle: 'Course catalog',
    cardHelperText: [
      'Please upload course catalogs for all academic years (2020-2024) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain at minimum course ID, course name, and number of credits per course.'
    ],
    modalTitle: 'Course catalogs required',
    displayTable: true,
    fetchData: getCourseCatalogUploadRequirements,
    fileType: fileTypes.COURSE_CATALOG,
  },
  {
    cardTitle: 'Student demographics',
    cardHelperText: [
      'Please upload student demographics for the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain student ID and the following demographic information: gender, race/ethnicity, and SPED, ELL, and free and reduced lunch status. Each file must contain student ID but demographic information can be uploaded in separate files.'
    ],
    modalTitle: 'Student demographics required',
    displayTable: true,
    fetchData: getStudentDemographicsUploadRequirements,
    fileType: fileTypes.STUDENT_DEMOGRAPHICS,
  },
  {
    cardTitle: 'Student transcripts',
    cardHelperText: [
      'Please upload transcripts for all students in the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain at minimum Student ID, School ID, course ID, course name, grade (number and/or letter), term, and credit earned.'
    ],
    modalTitle: 'Transcripts required',
    displayTable: false,
    fetchData: getStudentTranscriptsUploadRequirements,
    fileType: fileTypes.STUDENT_TRANSCRIPTS,
  },
  {
    cardTitle: 'Test scores',
    cardHelperText: [
      'Please upload test scores for all students in the graduating class (2023-2024 school year) for all schools participating in the Educational Opportunity Audit.',
      'Please ensure file(s) contain student ID, test type, and the following test scores: SAT, ACT, AP, and/or IB. Each file must contain student ID and test type, but test scores can be uploaded in separate files.'
    ],
    modalTitle: 'Test scores required',
    displayTable: true,
    fetchData: getTestScoresUploadRequirements,
    fileType: fileTypes.TEST_SCORES,
  },
  {
    cardTitle: 'CTE pathways',
    cardHelperText: [
      'Please upload CTE pathways for all academic years (2020-2024) for all schools participating in the Educational Opportunity Audit. ',
      'Please ensure file(s) contain at minimum course ID, course name, pathway name/ID and any additional information that is needed to represent pathways within your district.'
    ],
    modalTitle: 'CTE pathways required',
    displayTable: true,
    fetchData: getCTEPathwaysUploadRequirements,
    fileType: fileTypes.CTE_PATHWAYS
  }
];

export default DataUpload;