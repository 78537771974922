import PrimaryButton from '../reusable/PrimaryButton';
import SecondaryButton from '../reusable/SecondaryButton';
import ViewDemographicsIcon from '../assets/arrow-up-right-from-square.svg';
import { finalizeTranscriptSelection } from '../../services/transcriptsService';
import useUserDetails from '../hooks/useUserDetails';
import { useSelectedTranscripts } from '../hooks/useSelectedTranscripts';
import PageHeader from '../reusable/PageHeader';

import './TranscriptSelectionHeader.css';

interface TranscriptSelectionHeaderProps {
  isFinalized: boolean;
  selectedSchool: string;
  triggerRefresh: () => void;
  handleViewSchoolDemographics: () => void;
}

function TranscriptSelectionHeader({ 
  isFinalized, 
  selectedSchool,
  triggerRefresh,
  handleViewSchoolDemographics 
}: TranscriptSelectionHeaderProps) {

  const { data: userDetails } = useUserDetails();

  const { selectedTranscripts } = useSelectedTranscripts();

  const handleFinalizeTranscriptSelection = async () => {
    if (userDetails) {
      try {
        let transcriptsIds = selectedTranscripts.map(transcript => transcript.id);
        await finalizeTranscriptSelection(userDetails.eoas[0].id, userDetails.access_token, selectedSchool, transcriptsIds);
        triggerRefresh();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <PageHeader 
        title={isFinalized? 'Finalized transcripts': 'Transcript selection'}
        pageDescription="Some explanatory text"
      >
        <div className="transcript-selection-header-actions">
          {!isFinalized ?
            <PrimaryButton
              buttonText="Finalize transcript selection"
              disabled={selectedTranscripts.length === 0}
              buttonAction={handleFinalizeTranscriptSelection}
            />
            :
            null
          }
          <SecondaryButton
            buttonText="View school demographics"
            buttonAction={handleViewSchoolDemographics}
            iconPosition="end"
            icon={<img alt="view school demographics" src={ViewDemographicsIcon} />} />
        </div>
      </PageHeader>
    </>
  );
}

export default TranscriptSelectionHeader;