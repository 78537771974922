import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import './SelectDropdown.css';

type FormControlSize = 'small' | 'medium';

interface SelectDropdownProps {
  text: string;
  label: string;
  selectedValue: string;
  handleChange: (event: any) => void;
  handleDelete?: () => void;
  items: {
    label: string,
    value: string
  }[];
  containerClasses?: string[];
  formControlClasses?: string[];
  isClearable?: boolean;
  size?: FormControlSize;
}

function SelectDropdown({
  text, 
  label, 
  selectedValue, 
  handleChange, 
  handleDelete, 
  items, 
  containerClasses, 
  formControlClasses,
  isClearable = true,
  size
}: SelectDropdownProps) {

  const containerClassNames = containerClasses ? containerClasses.join(' ') : '';
  const formControlClassNames = formControlClasses ? formControlClasses.join(' ') : '';

  return (
    <div className={`filter ${containerClassNames}`} data-testid="form-control-container">
      <p>{`${text}: `}</p>
      <FormControl className={`${formControlClassNames} select`} size={size} data-testid="form-control">
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          label={label}
          value={selectedValue}
          onChange={handleChange}
          endAdornment={
            isClearable && selectedValue ? 
              (
                <InputAdornment sx={{ marginRight: '10px' }} position="end">
                  <IconButton
                    data-testid="clear-selection-icon"
                    onClick={handleDelete}
                  >
                    <ClearIcon/>
                  </IconButton>
                </InputAdornment>
              )
              :
              null
          }
        >
          { items?.map((item, index) => (
            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectDropdown;