import {  Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import './MultiSelectDropdown.css';
import { useState } from 'react';
import { ReactComponent as CustomDeleteIcon } from '../assets/chip-delete-icon.svg';

interface MultiSelectDropdownProps {
  items: any;
  parentCallback: (value: string[], value2: string) => void;
  title: string;
  itemName: string;
}

const MuiChipStyles = {
  backgroundColor: 'var(--blue)', 
  color: '#FFFFFF',
  paddingRight: '5px',
  '&:hover': {
    backgroundColor: 'var(--blue)',
    filter: 'brightness(0.9)'
  }
};

function MultiSelectDropdown({ items, parentCallback, title, itemName }: MultiSelectDropdownProps) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const isAllSelected = items.length > 0 && selectedOptions.length === items.length;

  const customStyles = {
    '&': {
      width: '60%',
      minWidth: '200px',
      maxWidth: '300px',
    },
    '& .MuiInputBase-root .MuiSelect-select': {
      whiteSpace: 'wrap',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      maxHeight: '50px',
      overflow: 'auto',
      gap: '5px',
    }
  };

  const handleChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value.length && value[value.length - 1].includes('All')) {

      parentCallback(selectedOptions.length === items.length ? [] : items, title.toLowerCase());
      setSelectedOptions(selectedOptions.length === items.length ? [] : items);
      return;
    }
    parentCallback(value, title.toLowerCase());
    setSelectedOptions(value);
  };

  const handleDelete = (value: string) => {
    const filteredOptions = selectedOptions.filter(selectedOption => selectedOption !== value);
    setSelectedOptions(filteredOptions);
    parentCallback(filteredOptions, title.toLowerCase());
  };

  return (
    <div className="multiselect-dropdown">
      <div className="dropdown-title">{title}</div>
      <FormControl data-testid="form-control" sx={customStyles}>
        <InputLabel id="select-label">Select</InputLabel>
        <Select
          labelId="mutiple-select-label"
          label="Select"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          renderValue={(selected) => 
            selected.map(selectedValue => {
              return <Chip 
                sx={MuiChipStyles}
                key={selectedValue} 
                label={selectedValue} 
                deleteIcon={<CustomDeleteIcon data-testid={`chip-delete-icon-${selectedValue}`}/>}
                onDelete={() => handleDelete(selectedValue)}
                onMouseDown={(event) => event.stopPropagation()}
                clickable
              />;
            })
          }
        >
          <MenuItem
            value="All"
          >
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selectedOptions.length > 0 && selectedOptions.length < items.length
              }
            />
            <ListItemText
              primary={`All ${itemName}s`}
            />
          </MenuItem>
          {items.length && items.map((option: string) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedOptions.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiSelectDropdown;
