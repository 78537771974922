import axios from 'axios';
import UserDetails from '../interfaces/UserDetailsInterface';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getUserDetails = async (access_token: string) => {
  return axios.get<UserDetails>(`${BASE_URL}/users/me`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
};

export {
  getUserDetails
};