import SecondaryButton from '../reusable/SecondaryButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './TranscriptsBySubjectArea.css';

function TranscriptsBySubjectArea() {
  return (
    <>
      <div className="transcripts-view-header">
        <h2>Transcript view by subject area</h2>
        <SecondaryButton buttonText="Print All" buttonAction={() => console.log('print all')} />
      </div>
      <div className="transcripts-view-body">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Math
          </AccordionSummary>
          <AccordionDetails>
            <table className="subjects-table">
              <thead>
                <tr>
                  <th></th>
                  <th>9TH GRADE</th>
                  <th>10TH GRADE</th>
                  <th>11TH GRADE</th>
                  <th>12TH GRADE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Student1</p>
                    <p>F, Black</p>
                    <p>SPED</p>
                  </td>
                  <td>
                    <div className="course-cell-data">
                      <div className="course-info">
                        <p>Course: Biology</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                      <div className="course-info">
                        <p>Course: Biology 2</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="course-cell-data">
                      <div className="course-info">
                        <p>Course: Biology</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                      <div className="course-info">
                        <p>Course: Biology 2</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="course-cell-data">
                      <div className="course-info">
                        <p>Course: Biology</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                      <div className="course-info">
                        <p>Course: Biology 2</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="course-cell-data">
                      <div className="course-info">
                        <p>Course: Biology</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                      <div className="course-info">
                        <p>Course: Biology 2</p>
                        <p>Code: (S2102)</p>
                        <p>Grade: C</p>
                        <p>Credits: 0.5</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
          <AccordionActions>
            <SecondaryButton buttonText="Print" buttonAction={() => console.log('print transcript by subject area')} />
          </AccordionActions>
        </Accordion>
      </div>
    </>
  );
}

export default TranscriptsBySubjectArea;