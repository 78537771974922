import PrimaryButton from './PrimaryButton';
import './ContainerWithButton.css';


interface ContainerWithButtonProps {
    buttonText: string;
    handleSubmit: () => void;
    children: any;
    disabled: boolean;
}

function ContainerWithButton({buttonText, handleSubmit, children, disabled}: ContainerWithButtonProps) {
    
  return (
    <main className="container-with-button">
      <div className="children-container">
        {children}
      </div>
      <div className="action-button-container">
        <PrimaryButton buttonText={buttonText} buttonAction={handleSubmit} disabled={disabled} />
      </div>
    </main>
  );
}

export default ContainerWithButton;