import { useState } from 'react';
import FileIcon from '../assets/file-icon.svg';
import CheckMarkIcon from '../assets/check-mark-icon.svg';
import XMarkIcon from '../assets/x-mark-icon.svg';
import XCircleIcon from '../assets/x-circle-icon.svg';
import RefreshIcon from '../assets/refresh-icon.svg';
import ExclamationIcon from '../assets/exclamation-icon.svg';
import PrimaryButton from './PrimaryButton';
import './FileUploadStatus.css';
import UploadResultsModal from '../data upload/UploadResultsModal';
import { FileUploadResult } from '../../interfaces/UploadedFileDataInterface';
import AuditLogModalTable from '../data upload/AuditLogModalTable';
import { UploadedFileStatus } from '../../interfaces/UploadedFileDataInterface';

interface FileUploadStatusProps {
  fileId: string;
  fileName: string;
  fileSize: string;
  fileStatus: keyof typeof UploadedFileStatus;
  results: FileUploadResult[];
  statistics: string[];
  onResubmit: (value: string) => void;
}

type ModalTypeString = null | 'upload results' | 'audit log';

function FileUploadStatus({ fileId, fileName, fileSize, fileStatus, results, statistics, onResubmit }: FileUploadStatusProps) {

  const [modalType, setModalType] = useState<ModalTypeString>(null);

  const handleCloseModal = () => {
    setModalType(null);
  };

  const handleOpenModal = (type: ModalTypeString) => {
    setModalType(type);
  };

  const handleResubmit = () => {
    onResubmit(fileId);
    handleCloseModal();
  };
  
  return (
    <div className={`file-upload-status ${fileStatus !== UploadedFileStatus.SUCCESS && fileStatus !== 'DATA_ERRORS' ? 'wrap' : ''}`}>
      <div className="file-info">
        <img src={FileIcon} alt="file icon"></img>
        <div className="file-name">
          <span>{fileName}</span>
          <div className="file-size-audit-log-container">
            <span className="file-size">{fileSize}</span>
            <span className="show-audit-log" onClick={() => handleOpenModal('audit log')}>Audit log</span>
          </div>
        </div>
      </div>
      <FileStatus fileStatus={fileStatus} handleClick={() => handleOpenModal('upload results')} />
      {modalType === 'upload results' &&
           <UploadResultsModal 
             modalType={modalType}
             handleCloseModal={handleCloseModal}
             handleResubmit={handleResubmit}
             results={results}
             statistics={statistics}
           />
      }
      {modalType === 'audit log' &&
            <AuditLogModalTable modalType={modalType} handleCloseModal={handleCloseModal}/>
      }
    </div>
  );
}

export function FileStatus({ fileStatus, handleClick }: { fileStatus: string, handleClick: () => void }) {
  if (fileStatus === 'SUCCESS') {
    return (
      <>
        <div className="file-status file-status-ok">
          <img src={CheckMarkIcon} alt="check mark icon"></img>
          <p>No data errors detected</p>
        </div>
        <PrimaryButton buttonText="Review" buttonAction={handleClick} />
      </>
    );
  }

  if (fileStatus === 'DATA_ERRORS') {
    return (
      <>
        <div className="file-status file-status-error">
          <img src={XMarkIcon} alt="x mark icon"></img>
          <p>Data errors detected</p>
        </div>
        <PrimaryButton buttonText="Review" buttonAction={handleClick} />
      </>
    );
  }

  if (fileStatus === 'PROCESSING') {
    return (
      <>
        <div className="progress-bar">
          <div className="progress-bar-track">
            <div data-testid="progress-bar" className="progress-bar-value progress-bar-value-process"></div>
          </div>
          <img src={XCircleIcon} alt="cancel processing icon"></img>
        </div>
        <div className="progress-bar-info">
          <p className="progress-bar-info-text">File uploaded</p>
          <p className="progress-bar-info-text">Processing data...</p>
        </div>
      </>
    );
  }

  if (fileStatus === 'UPLOAD FAILED') {
    return (
      <>
        <div className="progress-bar">
          <div className="progress-bar-track">
            <div data-testid="progress-bar" className="progress-bar-value-failed"></div>
          </div>
          <img src={RefreshIcon} alt="refresh icon"/>
        </div>
        <div className="progress-bar-info">
          <p className="progress-bar-info-text">File upload failed</p>
        </div>
      </>
    );
  }

  if (fileStatus === 'MALFORMED') {
    return (
      <>
        <div className="progress-bar">
          <div className="progress-bar-track">
            <div data-testid="progress-bar" className="progress-bar-value-failed"></div>
          </div>
          <img src={ExclamationIcon} alt="exclamation icon"/>
        </div>
        <div className="progress-bar-info">
          <p className="progress-bar-info-text">File processing failed</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="progress-bar">
        <div className="progress-bar-track">
          <div data-testid="progress-bar" className="progress-bar-value progress-bar-value-upload"></div>
        </div>
        <img src={XCircleIcon} alt="cancel processing icon"></img>
      </div>
      <div className="progress-bar-info">
        <p className="progress-bar-info-text">Uploading file...</p>
      </div>
    </>
  );
}

export default FileUploadStatus;