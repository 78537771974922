import CloseIcon from '../assets/close-icon.svg';
import QuestionMarkIcon from '../assets/qustion-mark-icon.svg';
import './SideDrawer.css';

interface SideDrawerProps {
  title: string;
  handleClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  classes: string[];
}

function SideDrawer({title, handleClose, children, isOpen, classes}: SideDrawerProps) {

  const classNames = classes ? classes.join(' ') : '';

  return (
    <>
      <div data-testid="side-drawer-container" className={`side-drawer-container ${!isOpen ? 'closed' : ''} ${classNames}`}>
        <div className="side-drawer-header">
          <p>{title}</p>
          <img className="pointer" alt="question mark icon" src={QuestionMarkIcon}/>
          <img className="pointer close-icon" onClick={handleClose} src={CloseIcon} alt="close icon" />
        </div>
        <div className="side-drawer-content">
          {children}
        </div>
      </div>
    </>
  );
}

export default SideDrawer;