import { Outlet } from 'react-router-dom';
import SideBarNavigation from './SideBarNavigation';
import { useRef, useState } from 'react';
import BurgerMenuIcon from '../assets/burger-menu.svg';
import './NavigationLayout.css';
import UserProfilePopover from './UserProfilePopover';
import UserProfileIcon from '../assets/user-profile-icon.svg';
import useUserDetails from '../hooks/useUserDetails';
import Footer from '../footer/Footer';
import Loader from '../reusable/Loader';

const NavigationLayout = () => {

  const { isPending, error, data: userDetails } = useUserDetails();

  const [isClosed, setIsClosed] = useState(false);

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement | null>(null);

  const handleClickUserProfile = () => {
    setIsProfileMenuOpen(true);
  };

  const handleToggleMenu = () => {
    setIsClosed(prev => !prev);
  };

  return (
    <div className="App">
      <div className="top-navigation">
        <img alt="burger menu icon" src={BurgerMenuIcon} style={{cursor: 'pointer'}} onClick={handleToggleMenu} />

        {isProfileMenuOpen && 
        <UserProfilePopover 
          anchorEl={anchorEl.current} 
          isProfileMenuOpen={isProfileMenuOpen} 
          handleIsProfileMenuOpen={setIsProfileMenuOpen} 
        />
        }
        <div data-testid="user-profile-container" ref={anchorEl} className="user-profile-container" onClick={handleClickUserProfile}>
          { isPending ? <Loader /> : null }
          { error ? <p>Missing user data!</p> : null }
          { userDetails ? 
            <>
              <img className="user-profile-icon" alt="user profile icon" src={UserProfileIcon}/>
              <div data-testid="user-role" className="user-role">{userDetails ? userDetails.eoas[0].roles[0]?.name : ''}</div>
            </>
            :
            null
          }
        </div>
      </div>
      <div data-testid="navigation-layouyt-main-container" className="main-container">
        <SideBarNavigation isClosed={isClosed}/>
        <div className="main-content">
          <Outlet />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default NavigationLayout;