import { Box, Checkbox, Collapse, IconButton, TableCell, tableCellClasses, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import TranscriptDetailsMatrix from './TranscriptDetailsMatrix';
import { useSelectedTranscripts } from '../hooks/useSelectedTranscripts';
import CustomModal from '../reusable/CustomModal';

interface TranscriptCollapsibleTableRowProps {
  row: {
    id: string,
    category: string,
    gpa: number,
    credits: number,
    race: string,
    gender: string,
    disability_status: string,
    english_learner: string,
    low_income: string, 
  };
  selectionLimit: number;
  selectedCount: number;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    whiteSpace: 'wrap'
  },
}));

function TranscriptCollapsibleTableRow({ row, selectionLimit, selectedCount }: TranscriptCollapsibleTableRowProps) {

  const [isExpanded, setIsExpanded] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { selectedTranscripts, handleSelectTranscripts } = useSelectedTranscripts();

  const isSelected = selectedTranscripts.some(transcript => transcript.id === row.id);

  const handleSelectedTranscripts = () => {
    if (isSelected) { 
      const updatedSelectedTranscripts = selectedTranscripts.filter(transcript => transcript.id !== row.id);
      handleSelectTranscripts(updatedSelectedTranscripts, isSelected);
      return;
    } 
    
    if (selectedCount + 1 > selectionLimit) {
      setIsModalOpen(true);
      return;
    }

    handleSelectTranscripts([...selectedTranscripts, row], isSelected);
  };

  return (
    <>
      <TableRow>
        {Object.entries(row).map(([key, value]) => 
          (key !== 'category' ?
            <StyledTableCell key={key} align="left">{value}</StyledTableCell> :
            null)
        )}
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Checkbox checked={isSelected} onChange={handleSelectedTranscripts} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={9} padding="none">
          <Collapse in={isExpanded} mountOnEnter>
            <Box>
              {/* REMOVE SLICING AFTER WE GET REAL DATA */}
              <TranscriptDetailsMatrix transcriptId={row.id.slice(0, -2)} /> 
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      { isModalOpen ?
        <CustomModal 
          isOpen={isModalOpen} 
          title="Limit reached!" 
          handleClose={() => setIsModalOpen(false)} 
          icon={false}
          maxWidth="xs"
        >
          <p className="no-margin">Selection limit reached!</p>
        </CustomModal>
        :
        null
      }
    </>
  );
}

export default TranscriptCollapsibleTableRow;