import { getTranscriptsSummary } from '../../../services/dataQcSummaryService';
import useFetchData from '../../hooks/useFetchData';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';
import CustomPagination from '../../reusable/CustomPagination';
import usePagination from '../../hooks/usePagination';
import { DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE } from '../../../constants';
import PaginationData from '../../../interfaces/PaginationInterface';

const mockPaginationData: PaginationData = { totalItems: 50, size: DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE, pages: 5 };

function TranscriptsContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getTranscriptsSummary(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching transcripts summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  const {currentPage, totalItems, pages, startItem, endItem, handlePageChange} = usePagination(mockPaginationData);

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing transcripts summary data!</p>;

  const transcriptsSummary = response.data;

  return (
    <div data-testid="transcripts-content">
      <p>We found transcripts for {transcriptsSummary.total_num_schools_with_transcripts} of the {transcriptsSummary.schools.length} schools in your district</p>
      <ul>
        <li>
          {`${transcriptsSummary.total_num_transcripts} transcripts total`}
          <ul className="list-type-disc">
            {transcriptsSummary.schools.map(({ id, name, transcript_count }) => (
              <li key={id}>{transcript_count} students at {name}</li>
            ))}
          </ul>
        </li>
      </ul>
      <CustomPagination
        currentPage={currentPage}
        totalItems={totalItems}
        pages={pages}
        startItem={startItem}
        endItem={endItem}
        handlePageChange={() => handlePageChange}
      />
    </div>
  );
}

export default TranscriptsContent;