import PrimaryButton from '../reusable/PrimaryButton';
import SecondaryButton from '../reusable/SecondaryButton';
import CustomModal from '../reusable/CustomModal';

import './TranscriptSelectionWarningModal.css';

interface TranscriptWarningModalProps {
  isOpen: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
}

function TranscriptWarningModal({ isOpen, handleConfirm, handleCancel }: TranscriptWarningModalProps) {
  return (
    <>
      {isOpen &&
      <CustomModal
        icon={false}
        isOpen={isOpen}
        title="Are you sure?"
        handleClose={handleCancel}
        maxWidth="xs"
      >
        <div className="modal-body">
          <span className="modal-body-content">
            This action may require contacting the lead facilitator or repeating certain steps of the process.
          </span>
          <div className="modal-actions">
            <p className="modal-actions-help">Transcript selection help</p>
            <SecondaryButton buttonText="Cancel" buttonAction={handleCancel} />
            <PrimaryButton buttonText="Confirm" buttonAction={handleConfirm} />
          </div>
        </div>
      </CustomModal>
      }
    </>
  );
}

export default TranscriptWarningModal;