import SecondaryButton from '../reusable/SecondaryButton';
import CustomTable from '../reusable/CustomTable';
import TranscriptsBySubjectArea from './TranscriptsBySubjectArea';
import { Transcript } from '../../interfaces/TranscriptsInterface';
import { useSelectedTranscripts } from '../hooks/useSelectedTranscripts';
import Loader from '../reusable/Loader';

import './TranscriptSelectionTables.css';
import TranscriptCollapsibleTableRow from './TranscriptCollapsibleTableRow';

interface TranscriptSelectionTablesProps {
  isFinalized: boolean;
  categories: {
    name: string;
    label: string;
    filteredData: Transcript[];
    selectionLimit: number;
  }[] | null;
}

const tableHeaderData = ['Student', 'GPA', 'Credits', 'Race/Eth', 'Gender', 'Disability status', 'English learner', 'Low income', 'Actions'];

function TranscriptSelectionTables({ isFinalized, categories }: TranscriptSelectionTablesProps) {
  const { selectedTranscripts } = useSelectedTranscripts();

  if(!categories) return <Loader />;
  
  return (
    <div className="transcript-selection-tables-container">
      {categories.map(({ name, label, filteredData, selectionLimit }, categoryIndex) => {
        const selectedCount = selectedTranscripts.filter(transcript => transcript.category === name).length;
        return (
          <div className="transcript-selection-category-container" key={name}>
            <div className="transcript-selection-category-header">
              <p className="transcript-selection-category-title">
                {`${label} (${selectedCount} / ${selectionLimit} selected)`}
              </p>
              <div className="transcript-selection-category-header-actions">
                <SecondaryButton buttonText="Print All" buttonAction={() => console.log('print all')} />
                { isFinalized ?
                  null
                  :
                  <SecondaryButton buttonText="Save selection progress" buttonAction={() => console.log('save selection progress')} />
                }
              </div>
            </div>
            <CustomTable tableHeaderData={tableHeaderData}> 
              {filteredData.map((row, index) => (
                <TranscriptCollapsibleTableRow
                  key={`${row.id}-${index}`} 
                  row={{...row, id: row.id + categoryIndex + index}} // REMOVE ADDITIONAL INDICES WHEN WE HAVE REAL DATA
                  selectionLimit={selectionLimit}
                  selectedCount={selectedCount}
                />
              ))}
            </CustomTable>
          </div>
        );
      })}
      { isFinalized ?
        <div className="transcript-selection-category-container transcripts-view">
          <TranscriptsBySubjectArea />
        </div>
        :
        null
      }
    </div>
  );
}

export default TranscriptSelectionTables;