import CustomModal from '../reusable/CustomModal';
import PrimaryButton from '../reusable/PrimaryButton';
import SecondaryButton from '../reusable/SecondaryButton';
import CheckMarkIcon from '../assets/check-mark-icon.svg';
import XMarkIcon from '../assets/x-mark-icon.svg';
import { FileUploadResult } from '../../interfaces/UploadedFileDataInterface';

interface UploadResultsModalProps {
    modalType: string | null;
    handleCloseModal: () => void;
    handleResubmit: () => void;
    results: FileUploadResult[];
    statistics: string[];
};

function UploadResultsModal({modalType, handleCloseModal, handleResubmit, results, statistics}: UploadResultsModalProps) {
  return (
    <>
      <CustomModal 
        title="Upload results" 
        isOpen={modalType === 'upload results'} 
        handleClose={handleCloseModal} 
        icon={false}
        dividers={true}
      >
        {results.map((result, index) => (
          <div key={index} className="result-container">
            {result.status === 'success' ?
              <img src={CheckMarkIcon} alt="check mark icon"></img>
              :
              <img src={XMarkIcon} alt="x mark icon"></img>
            }
            <p>{result.description}</p>
          </div>
        ))}
        <ul>
          {statistics.map((statistic, index) => <li key={index}>{statistic}</li>)}
        </ul>
        <div className="actions-container">
          <SecondaryButton buttonText="Resubmit" buttonAction={handleResubmit} />
          <PrimaryButton buttonText="Continue" buttonAction={handleCloseModal} />
        </div>
      </CustomModal>
    </>
  );
}

export default UploadResultsModal;