import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { getCtePathwaysDetails } from '../../../services/dataQcDetailsService';
import useFetchData from '../../hooks/useFetchData';
import './CtePathwaysSideDrawerContent.css';
import SelectDropdown from '../../reusable/SelectDropdown';
import CustomTabs from '../../reusable/CustomTabs';
import FlowDiagram from '../flow diagram/FlowDiagram';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';

const items = [
  { label: 'Overview', disabled: false },
  { label: 'Mapping', disabled: true }
];

function CtePathwaysSideDrawerContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getCtePathwaysDetails(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching CTE pathways details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  const [selectedTab, setSelectedTab] = useState(0);
  //will there be preselected period ?
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const [selectedPathway, setSelectedPathway] = useState<string>('');

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing CTE pathways details data!</p>;

  const data = response.data;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSelectPeriod = (event: SelectChangeEvent) => {
    setSelectedPeriod(event.target.value);
  };

  const handleSelectPathway = (event: SelectChangeEvent) => {
    setSelectedPathway(event.target.value);
  };

  const renderAcademicPeriodData = () => {
    
    const filteredData = data.academic_periods.filter(period => period.period === selectedPeriod)[0];
    
    return (
      <div className="cte-details-summary" data-testid="cte-details-container">
        {filteredData ? 
          <ul data-testid="cte-details-list">
            <li>{`${filteredData.cte_catalogs_count} CTE catalogs `}</li>
            <li>{`${filteredData.cte_eligible_courses_count} classes eligible for CTE pathways`}</li>
            <li>{`${filteredData.cte_pathways_count} CTE pathways`}</li>
          </ul>
          :
          null}
      </div>
    );
  };

  const getPathways = () => {
    return data.academic_periods
      .flatMap(academicPeriod => academicPeriod.pathways
        .map(pathway => {
          return { label: pathway.name, value: pathway.name };
        }));
  };
  
  return (
    <div className="cte-side-drawer-content" data-testid="cte-pathways-content">
      <SelectDropdown 
        text="Year"
        label="Select"
        selectedValue={selectedPeriod}
        handleChange={handleSelectPeriod}
        handleDelete={() => setSelectedPeriod('')}
        items={data.academic_periods.map(period => {
          return { label: period.period, value: period.period };
        })}
        formControlClasses={['width-40']}
      />
      <div className="cte-details-container">
        <CustomTabs 
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          items={items}
        />
        {renderAcademicPeriodData()}
      </div>
      <div className="separator-line"></div>
      <div className="flow-diagram-details-container">          
        <SelectDropdown 
          text="View pathways in"
          label="Select"
          selectedValue={selectedPathway}
          handleChange={handleSelectPathway}
          handleDelete={() => setSelectedPathway('')}
          items={getPathways()}
          formControlClasses={['width-40']}
        />
    
        {/* waiting for structured real data to render FlowDiagram based on selected pathway*/}
        <div className="flow-diagram-container">
          <FlowDiagram />
        </div>
      </div>
    </div>
  );
}

export default CtePathwaysSideDrawerContent;