import { Box, Pagination, Stack } from '@mui/material';
import './CustomPagination.css';
interface CustomPaginationProps {
   currentPage: number;
   totalItems: number;
   pages: number;
   startItem: number;
   endItem: number;
   handlePageChange: (event: React.ChangeEvent<any>, newPage: number) => void;
}

function CustomPagination({ currentPage, totalItems, pages, startItem, endItem, handlePageChange}: CustomPaginationProps) {

  return (
    <div className="pagination-container">
      <div>
          Showing <span data-testid="paginated-start-end-item" className="bolded">{startItem}-{endItem}</span> of <span className="bolded">{totalItems}</span>
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Stack spacing={2}>
          <Pagination
            variant="outlined" 
            shape="rounded"
            onChange={handlePageChange}
            count={pages}
            page={currentPage}
            boundaryCount={2}
            siblingCount={0}
            showFirstButton={false}
            showLastButton={false}
          />
        </Stack>
      </Box>
    </div>
  );
}

export default CustomPagination;
