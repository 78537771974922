import './CardContainer.css';
interface CardContainerProps {
  title: string;
  centerTitle?: boolean;
  children: React.ReactNode;
  classes?: Array<string>;
  button?: React.ReactNode;
  customHeader?: React.ReactNode;
}

function CardContainer({title, children, classes, button, customHeader, centerTitle = false}: CardContainerProps) {

  const classNames = classes ? classes.join(' ') : '';

  return (
    <div className={`card-container ${classNames}`} data-testid="card-container">
      <div className={`card-container-header ${centerTitle ? 'centered' : ''}`} data-testid="card-container-header">
        { customHeader ?
          customHeader
          :
          <>
            <p className="card-container-title">{title}</p>
            {button ? button : null}
          </>
        }
      </div>
      <div className="card-container-content" data-testid="card-container-content">
        {children}
      </div>
    </div>
  );
}

export default CardContainer;