import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { getCourseCatalogsDetails } from '../../../services/dataQcDetailsService';
import useFetchData from '../../hooks/useFetchData';
import CardContainer from '../CardContainer';
import SelectDropdown from '../../reusable/SelectDropdown';
import CustomTabs from '../../reusable/CustomTabs';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';
import { AcademicPeriod } from '../../../interfaces/DataQcInterface';

import './CourseCatalogSideDrawerContent.css';

const items = [
  { label: 'Overview', disabled: false },
  { label: 'Course Mapping', disabled: true }
];

function CourseCatalogSideDrawerContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getCourseCatalogsDetails(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching course catalog details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });
  
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing course catalog details data!</p>;

  const data = response.data;

  const filterData = () => {
    if (!selectedPeriod || selectedPeriod.length === 0) return data.academic_periods;

    return data.academic_periods.filter(academicPeriod => academicPeriod.period === selectedPeriod);
  };

  const renderData = (filteredData: AcademicPeriod[]) => {
    return filteredData.map((academicPeriod, index) => (
      <ul key={index} className="list-type-disc">
        <li>We found courses in {academicPeriod.subject_area_count} subject areas</li>
        {academicPeriod.subject_areas.map((subjectArea, index) => (
          <ul key={index} className="list-type-disc" data-testid="subject-area-list">
            <li>{subjectArea.course_count} {subjectArea.name} courses
              <ul className="list-type-disc">
                <li>{subjectArea.ap_count} AP courses</li>
                <li>{subjectArea.ib_count} IB courses</li>
                <li>{subjectArea.general_count} general courses</li>
                <li>{subjectArea.honors_count} honors courses</li>
                <li>{subjectArea.cte_count} CTE courses</li>
              </ul>
            </li>
          </ul>
        ))}
      </ul>
    ));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedPeriod(event.target.value);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <CardContainer 
      title=""
      customHeader={
        <div className="course-catalog-details-header">
          <SelectDropdown
            text="Year"
            label="Select"
            selectedValue={selectedPeriod}
            handleChange={handleChange}
            handleDelete={() => setSelectedPeriod('')}
            items={data.academic_periods.map(period => {
              return { label: period.period, value: period.period };
            })}
          />
        </div>
      }
    >
      <CustomTabs 
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        items={items}
      />
      {/* TODO: add check for current tab */}
      { renderData(filterData()) }
    </CardContainer>
  );
}

export default CourseCatalogSideDrawerContent;