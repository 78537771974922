import { Link, useNavigate } from 'react-router-dom';
import CardContainer from './CardContainer';
import DemographicsContent from './demographics/DemographicsContent';
import RosterContent from './RosterContent';
import TranscriptsContent from './transcripts/TranscriptsContent';
import CteCoursesContent from './cte courses/CteCoursesContent';
import MatchingContent from './matching/MatchingContent';
import TestScoresContent from './test scores/TestScoresContent';
import CourseCatalogsContent from './course catalog/CourseCatalogsContent';
import OpenModalIconButton from '../reusable/OpenModalIconButton';

import './DataQcSummary.css';

const cards = [
  {title: 'Demographics', component: <DemographicsContent />, path: '', shouldRenderViewMore: false},
  {title: 'Roster', component: <RosterContent />, path: '', shouldRenderViewMore: false},
  {title: 'Test scores', component: <TestScoresContent />, path: '/data-qc/test-scores', shouldRenderViewMore: true},
  {title: 'Matching', component: <MatchingContent />, path: '/data-qc/matching', shouldRenderViewMore: true},
  {title: 'CTE courses', component: <CteCoursesContent />, path: '/data-qc/cte-courses', shouldRenderViewMore: true},
  {title: 'Course catalogs', component: <CourseCatalogsContent />, path: '/data-qc/course-catalogs', shouldRenderViewMore: true},
  {title: 'Transcripts', component: <TranscriptsContent />, path: '/data-qc/transcripts', shouldRenderViewMore: true},
];

function DataQcSummary() {
  const navigate = useNavigate();

  const handleOpen = (path: string) => {
    if (path) navigate(path);
  };

  const renderViewMoreButton = (shouldRenderViewMore: boolean, path: string) => {
    if (shouldRenderViewMore) {
      return (
        <OpenModalIconButton handleOpen={() => handleOpen(path)} buttonLabel="View more" />
      );
    }
  };

  return (
    <div className="data-qc-main-content">
      <div className="data-qc-info-container">
        <h3 className="data-qc-info-title">Does this information look wrong?</h3>
        <p>
      XQ may be missing information about your district. 
        You can fix this by uploading more data or editing errors on
         the <Link className="link" to="/data-upload">Data Upload page.</Link> For more help contact your <Link className="link" to="/help">XQ account manager</Link>.
        </p>
      </div>
      <div className="data-qc-cards-container">
        {cards.map(({title, component, path, shouldRenderViewMore}, index) => (
          <CardContainer key={index} title={title} button={renderViewMoreButton(shouldRenderViewMore, path)} classes={['data-qc-summary-card']}>
            {component}
          </CardContainer>
        ))}
      </div>
    </div>
  );
}

export default DataQcSummary;