import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '../assets/logout-icon.svg';
import './UserProfilePopover.css';
import { Dispatch, SetStateAction } from 'react';
import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '../assets/info-icon.svg';

interface UserProfilePopoverProps {
    anchorEl: HTMLDivElement | null;
    isProfileMenuOpen: boolean;
    handleIsProfileMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const CustomPopover = styled(Popover)(() => ({
  '& .MuiPaper-root' : {// Simulate click outside the popover to close it
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--constant-spacing-medium)',
    padding: 'var(--constant-spacing-regular) 4rem var(--constant-spacing-regular) var(--constant-spacing-regular)',
    borderRadius: 'var(--border-radius)',
    background: 'var(--container-background-color-light)',
    boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.05)'
  }
}));

const UserProfilePopover = ({
  anchorEl, 
  isProfileMenuOpen,
  handleIsProfileMenuOpen, 
}: UserProfilePopoverProps) => {

  const { logout } = useAuth0();

  const handleClose = () => {
    handleIsProfileMenuOpen(false);
  };

  return <>
    <CustomPopover
      open={isProfileMenuOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div className="settings-container">
        <img alt="settings info icon" src={InfoIcon}/>
        Settings
      </div>
      <div data-testid="logout-container" className="logout-container" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} >
        <img alt="logout button" src={LogoutIcon}/>
        Sign Out
      </div>
    </CustomPopover>
  </>;
};

export default UserProfilePopover;