import { useState } from 'react';
import PaginationData from '../../interfaces/PaginationInterface';

export default function usePagination(paginationData: PaginationData) {
    
  const [currentPage, setCurrentPage] = useState(1);

  const { totalItems, size, pages } = paginationData;

  const startItem = (currentPage - 1) * size + 1;
  const endItem = Math.min(currentPage * size, totalItems);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return { currentPage, totalItems, size, pages, startItem, endItem, handlePageChange };
}
