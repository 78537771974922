import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { OutletContextValues } from '../hooks/useDataQcOutletContext';
import useSchools from '../hooks/useSchools';
import Loader from '../reusable/Loader';
import PageHeader from '../reusable/PageHeader';

import SelectDropdown from '../reusable/SelectDropdown';

function DataQcLayout() {
  const [selectedSchool, setSelectedSchool] = useState('');

  const { schools: {isPending, error, data: schools }} = useSchools();

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedSchool(event.target.value);
  };

  return (
    <div>
      <PageHeader title="Data QC">
        {isPending ? <Loader /> : null}
        {error ? <p>Missing schools data!</p> : null}
        {schools ?
          <SelectDropdown
            text="Students in EOA cohort at"
            label="Select"
            selectedValue={selectedSchool}
            handleChange={handleChange}
            handleDelete={() => setSelectedSchool('')}
            items={schools.map(school => {
              return { label: school.name, value: school.id };
            })}
            containerClasses={['align-right']}
          />
          :
          null
        }
      </PageHeader>
      <Outlet context={{ selectedSchoolFromDataQc: selectedSchool } satisfies OutletContextValues}/>
    </div>
  );
}

export default DataQcLayout;