import UploadCard from './UploadCard';
import WhatToUpload from './WhatToUpload';
import SchoolsList from '../SchoolsList';
import SchoolTable from '../SchoolsTable';
import { UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import { UploadRequirementsList, UploadRequirementsTable } from '../../interfaces/UploadedFileDataInterface';
import FileTypesValues from '../../interfaces/FileType';
import useFetchData from '../hooks/useFetchData';
import { AxiosResponse } from 'axios';

interface UploadCardContainerProps {
  cardTitle: string;
  cardHelperText: string[];
  displayTable: boolean;
  uploadedFilesData: UploadedFileData[];
  fetchData: (value: string, value2: string) => Promise<AxiosResponse<UploadRequirementsTable> | AxiosResponse<UploadRequirementsList>>;
  modalTitle: string;
  fileType: FileTypesValues;
}

function UploadCardContainer({ 
  cardTitle, 
  cardHelperText, 
  modalTitle, 
  displayTable, 
  uploadedFilesData, 
  fetchData, 
  fileType
}: UploadCardContainerProps) {
  const { isPending, error, response } = useFetchData({
    fetchData, 
    errorMessage: 'Error occured while fetching upload requirements data!'
  });

  return (
    <>
      <UploadCard
        title={cardTitle}
        helperText={cardHelperText}
        modalData={
          <WhatToUpload title={modalTitle}>
            {displayTable ?
              <SchoolTable isPending={isPending} error={error} response={response as AxiosResponse<UploadRequirementsTable>} />
              :
              <SchoolsList isPending={isPending} error={error} response={response as AxiosResponse<UploadRequirementsList>} />
            }
          </WhatToUpload>
        }
        modalTitle={modalTitle}
        uploadedFilesData={uploadedFilesData}
        fileType={fileType}
      />
    </>
  );
}

export default UploadCardContainer;
