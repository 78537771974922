import { getRostersSummary } from '../../services/dataQcSummaryService';
import useFetchData from '../hooks/useFetchData';
import { useDataQcOutletContext } from '../hooks/useDataQcOutletContext';
import Loader from '../reusable/Loader';

function RosterContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getRostersSummary(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching rosters summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing rosters summary data!</p>;

  const rostersSummary = response.data;

  return (
    <div>  
      <div>{`${rostersSummary.total_student_count} students covering ${rostersSummary.schools.length} schools`}</div>
      <ul >
        {rostersSummary.schools.map(({id, name, student_count}) => (
          <li key={id}>{`${student_count} students at ${name}`}</li>
        ))}
      </ul>
    </div>
  );
}

export default RosterContent;