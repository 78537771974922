import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

interface SecondaryButtonProps {
  buttonText: string;
  buttonAction: () => void;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
}

const StyledSecondaryButton = styled(Button, { shouldForwardProp: () => true })(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  padding: 'var(--scaling-spacing-small) var(--scaling-spacing)',
  '&:hover': {
    backgroundColor: 'white',
    filter: 'brightness(0.95)'
  }
}));

export default function SecondaryButton({ buttonText, buttonAction, icon, iconPosition} : SecondaryButtonProps) {
  return (
    <StyledSecondaryButton
      onClick={buttonAction}
      variant="contained"
      startIcon={iconPosition === 'start' ? icon : null}
      endIcon={iconPosition === 'end' ? icon : null}
    >
      {buttonText}
    </StyledSecondaryButton>
  );
}