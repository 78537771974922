import FileIcon from '../assets/file-icon.svg';
import DownloadIcon from '../assets/download-icon.svg';
import GreenCheckBox from './GreenCheckBox';
import EmptyCheckBox from './EmptyCheckBox';
import ErrorStatusIcon from './ErrorStatusIcon';
import './WhatToUpload.css';

const uploadStatuses = [
  {status: 'Uploaded(no errors)', icon: <GreenCheckBox />},
  {status: 'Uploaded(with errors)', icon: <ErrorStatusIcon />},
  {status: 'Not yet uploaded', icon: <EmptyCheckBox />}
];

interface WhatToUploadProps {
  title: string;
  children: React.ReactNode;
}

const WhatToUpload = ({ title, children }: WhatToUploadProps) => {

  const renderExampleFile = () => {
    
    const fileName = `${title.replace('required', '')}Sample File.xlsx`;

    return (
      <>
        <div className="file-info-container">
          <div>Example file</div>
        </div>
        <div className="spacer"></div>
        <a data-testid="example file link" href={`/example files/${fileName}`} download={fileName}>
          <img className="pointer" alt="download icon" src={DownloadIcon} />
        </a>
      </>
    );
  };
  return (
    <>
      <div className="what-to-upload-container">
        <div className="section-title">Example files</div>
        <div className="example-files-container">
          <div className="example-file-container">
            <img alt="file icon" src={FileIcon}/>
            {renderExampleFile()}
          </div>
        </div>
      </div>
      <div className="what-to-upload-container">
        <p className="section-title">{title}</p>
        <div className="required-data-container">
          <div className="upload-statuses-container">
            {uploadStatuses.map(({status, icon}, index) => (
              <div key={index} className="upload-status">
                {icon}
                <div>{status}</div>
              </div>
            ))}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default WhatToUpload;