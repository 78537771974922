import { fileTypes } from '../../../constants';
import useSchools from '../../hooks/useSchools';
import useUserDetails from '../../hooks/useUserDetails';
import CustomModal from '../../reusable/CustomModal';
import { useEffect, useState, useRef } from 'react';
import { getAcademicYears, getDemographicCategories, getTestTypes, postRequestUploadUrl, uploadFileToS3Bucket } from '../../../services/uploadService';
import MultiSelectDropdown from '../../reusable/MultiSelectDropdown';
import ContainerWithButton from '../../reusable/ContainerWithButton';
import { DATA_UPLOAD_REDUCER_ACTIONS } from '../dataUploadReducer';
import { AxiosError, AxiosProgressEvent } from 'axios';
import useUploadResults from '../../hooks/useUploadResults';
import FileTypesValues from '../../../interfaces/FileType';
import Loader from '../../reusable/Loader';
import useErrorModal from '../../hooks/useErrorModal';
import { UploadedFileStatus } from '../../../interfaces/UploadedFileDataInterface';

import '../../reusable/ContainerWithButton.css';

const modalTitles = {
  [fileTypes.STUDENT_ROSTER]: 'Roster upload',
  [fileTypes.COURSE_CATALOG]: 'Course catalog upload',
  [fileTypes.STUDENT_DEMOGRAPHICS]: 'Student demographics upload',
  [fileTypes.STUDENT_TRANSCRIPTS]: 'Student transcripts upload',
  [fileTypes.TEST_SCORES]: 'Test scores upload',
  [fileTypes.CTE_PATHWAYS]: 'CTE upload'
};

const secondDropdownTitles = {
  [fileTypes.STUDENT_ROSTER]: '',
  [fileTypes.COURSE_CATALOG]: 'Academic year',
  [fileTypes.STUDENT_DEMOGRAPHICS]: 'Demographics type',
  [fileTypes.STUDENT_TRANSCRIPTS]: '',
  [fileTypes.TEST_SCORES]: 'Test type',
  [fileTypes.CTE_PATHWAYS]: 'Academic year'
};

const secondDropdownItemNameByFileType = {
  [fileTypes.TEST_SCORES]: 'test type',
  [fileTypes.COURSE_CATALOG]: 'year',
  [fileTypes.CTE_PATHWAYS]: 'year',
  [fileTypes.STUDENT_DEMOGRAPHICS]: 'demographic'
};

interface UploadPromptProps {
  fileName: string;
  isOpen: boolean;
  handleClose: () => void;
  fileType: FileTypesValues;
  file: any;
  isRosterUpload: boolean;
  secondDropdownTitle?: string;
  fileIdToReplace?: string;
};

interface SelectedData {
  [key: string]: string[]
}

interface SecondDropdownData {
  isPending: boolean;
  error: unknown;
  data: string[] | null;
}

function UploadPrompt({
  fileName, 
  isOpen, 
  handleClose, 
  fileType, 
  file ,
  isRosterUpload = false, 
  fileIdToReplace
}: UploadPromptProps) {

  const { data: userDetails } = useUserDetails();

  const { schools: {isPending: isSchoolsPending, error: schoolsError, data: schools }} = useSchools();

  const [{ 
    isPending: isSecondaryDropdownDataPending, 
    error: secondaryDropdownDataError, 
    data: secondaryDropdownData 
  }, setSecondDropdownData] = useState<SecondDropdownData>({ isPending: true, error: null, data: null });

  const [selectedData, setSelectedData] = useState<SelectedData>({});

  const uploadedFileId = useRef<string | null>(null);

  const { dispatch } = useUploadResults();

  const { handleOpen: handleOpenErrorModal } = useErrorModal();

  const closeModalAndClearSelected = () => {
    setSelectedData({});
    handleClose();
  };

  useEffect(() => {
    if (userDetails && isOpen) {
      getSecondDropdownData(userDetails.eoas[0].id, userDetails.access_token);
    }
  }, [userDetails, isOpen]);

  const getSecondDropdownData = async (eoaId: string, access_token: string) => {
    try {
      switch(fileType) {
      case fileTypes.COURSE_CATALOG:
      case fileTypes.CTE_PATHWAYS: {
        const response = await getAcademicYears(eoaId, access_token);
        const data = response.data;
        setSecondDropdownData({ isPending: false, error: null, data });
        break;
      }
      case fileTypes.STUDENT_DEMOGRAPHICS: {
        const response = await getDemographicCategories(eoaId, access_token);
        const data = await response.data;
        setSecondDropdownData({ isPending: false, error: null, data });
        break;
      }
      case fileTypes.TEST_SCORES: {
        const response = await getTestTypes(eoaId, access_token);
        const data = await response.data;
        setSecondDropdownData({ isPending: false, error: null, data });
        break;
      }
      default:
        setSecondDropdownData({ isPending: false, error: new Error('Invalid file type!'), data: null });
        break;
      } 
    } catch (error) {
      setSecondDropdownData({ isPending: false, error: error, data: null });
    }
  };

  const getItemsFromMultiSelect = (selectedNames: string[], title: string) => {
    if (selectedNames.length > 0) {
      selectedData[title] = selectedNames;
    } else {
      delete selectedData[title];
    }

    setSelectedData({ ...selectedData });
  };

  const handleSubmit = async () => {
    if (userDetails) {
      try {

        let requestData = {
          file_name: fileName,
          file_metadata: selectedData,
          upload_file_type: fileType,
          replaces_file_id: fileIdToReplace
        };
        const urlResponse = await postRequestUploadUrl(userDetails.eoas[0].id, userDetails.access_token, requestData);
        let fileId = urlResponse.data.s3_key.split('/').at(-1) as string;
        uploadedFileId.current = fileId;
  
        closeModalAndClearSelected();
  
        let fileData = {
          file_id: fileId,
          file_name: fileName,
          upload_file_type: fileType,
          status: UploadedFileStatus.UPLOAD,
          statistics: [],
          results_summary: []
        };
  
        if (!fileIdToReplace) {
          dispatch({ 
            type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA, 
            payload: fileData 
          });
        }
        else {
          dispatch({ 
            type: DATA_UPLOAD_REDUCER_ACTIONS.REPLACE_UPLOADED_FILE, 
            payload: { idToReplace: fileIdToReplace, newFileData: fileData } 
          });
        }
        
        uploadFileToS3Bucket(urlResponse.data.presigned_url, file, handleUploadProgress);
      } catch (error) {
        handleOpenErrorModal('Error occured while uploading file!', error as AxiosError);
      }
    }
  };

  const handleUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (progressEvent.total) {
      // const percentCompleted = Math.round(
      //   (progressEvent.loaded * 100) / progressEvent.total
      // );
      let fileData = {
        file_id: uploadedFileId.current as string,
        file_name: fileName,
        upload_file_type: fileType,
        status: UploadedFileStatus.PROCESSING,
        statistics: [],
        results_summary: []
      };
      dispatch({ type: DATA_UPLOAD_REDUCER_ACTIONS.UPDATE_UPLOADED_FILES_DATA, payload: fileData });
    }
  };
    
  const renderDropdowns = () => {
    const shouldRenderSecondDropdown = fileType !== fileTypes.STUDENT_ROSTER && fileType !== fileTypes.STUDENT_TRANSCRIPTS;
  
    return (
      <>
        { shouldRenderSecondDropdown && isSecondaryDropdownDataPending ? <Loader /> : null }
        { shouldRenderSecondDropdown && secondaryDropdownDataError ? <p>Data error!</p> : null }
        { shouldRenderSecondDropdown && secondaryDropdownData ? 
          <MultiSelectDropdown 
            items={secondaryDropdownData} 
            parentCallback={getItemsFromMultiSelect} 
            title={secondDropdownTitles[fileType]}
            itemName={secondDropdownItemNameByFileType[fileType]}
          />
          :
          null
        }
        { isSchoolsPending ? <Loader /> : null }
        { schoolsError ? <p>Missing schools data!</p> : null }
        { schools ?
          <MultiSelectDropdown 
            items={schools.map(school => school.name)} 
            parentCallback={getItemsFromMultiSelect} 
            title="Schools" 
            itemName="school"
          />
          :
          null
        }
      </>
    );
  };
  
  return (
    <>
      {isOpen &&
      <CustomModal title={modalTitles[fileType]} icon={false} isOpen={isOpen}  handleClose={closeModalAndClearSelected}> 
        <ContainerWithButton buttonText="Submit" handleSubmit={handleSubmit} disabled={Object.keys(selectedData).length === 0}>
          {renderDropdowns()}
        </ContainerWithButton>
      </CustomModal>
      }
    </>
  );
}

export default UploadPrompt;