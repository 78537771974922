import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '../assets/qustion-mark-icon.svg';
// import PrimaryButton from './PrimaryButton';
// import SecondaryButton from './SecondaryButton';
import './CustomModal.css';
interface CustomModalProps {
  title: string;
  children: any;
  isOpen: boolean;
  handleClose: () => void;
  icon: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  sx?: {[key: string]: any};
  dividers?: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root' : {
    borderRadius: 'var(--border-radius)',
    border: '1px solid var(--border-color)',
    background: '#FFF',
    boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.05)',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogTitle-root': {
    padding: 0,
    marginRight: 'var(--scaling-spacing-small)',
    fontSize: 'var(--font-size-large)'
  },
  '& .MuiIconButton-root': {
    padding: 0,
    marginLeft: 'auto'
  },
}));

function CustomModal({ title, children, isOpen, handleClose, icon, maxWidth = 'sm', sx={}, dividers }: CustomModalProps) {
  return ( <>
  
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth={true}
      sx={sx}
    >
      <div className="dialog-title-container">
        <DialogTitle id="customized-dialog-title">
          {title}
        </DialogTitle>
        { icon && <img className="pointer" alt="question mark icon" src={QuestionMarkIcon}/> }
        <IconButton
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent dividers={dividers}>
        {children}
      </DialogContent>
      {/* {actions &&
       <DialogActions>
         <PrimaryButton buttonText="Ok" buttonAction={handleOk}/>
         <SecondaryButton buttonText="Cancel" buttonAction={handleCancel} />
       </DialogActions>
      } */}
    </BootstrapDialog>
  </>
    
  );
}

export default CustomModal;