import { createContext } from 'react';
import { Transcript } from '../interfaces/TranscriptsInterface';

interface TranscriptSelectionContextValue {
  selectedTranscripts: Transcript[];
  // REMOVE isSelected PARAMETER AFTER XQ REVIEW
  handleSelectTranscripts: (newTranscriptSelection: Transcript[], isSelected: boolean) => void;
}

const TranscriptSelectionContext = createContext<TranscriptSelectionContextValue>({
  selectedTranscripts: [],
  handleSelectTranscripts: () => {}
});

export default TranscriptSelectionContext;