import { getTestScoresSummary } from '../../../services/dataQcSummaryService';
import useFetchData from '../../hooks/useFetchData';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';

function TestScoresContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getTestScoresSummary(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching test scores summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing test scores summary data!</p>;
  
  const testScoresSummary = response.data;

  const {
    sat_act_score_count, 
    sat_score_count, 
    act_score_count, 
    ap_score_count, 
    ib_score_count
  } = testScoresSummary.total;

  const {
    sat_act_score_count: cohort_sat_act_score_count, 
    sat_score_count: cohort_sat_score_count, 
    act_score_count: cohort_act_score_count, 
    ap_score_count: cohort_ap_score_count, 
    ib_score_count: cohort_ib_score_count
  } = testScoresSummary.cohort;

  return (
    <>
      <p>All students</p>
      <ul data-testid="all-students-test-scores">
        <li>
          {sat_act_score_count}% SAT/ACT superscores
          <ul className="list-type-disc">
            <li>{sat_score_count}% SAT superscores</li>
            <li>{act_score_count}% ACT superscores</li>
          </ul>
        </li>
        <li>{ap_score_count}% AP superscores</li>
        <li>{ib_score_count}% IB superscores</li>
      </ul>

      <p className="transcripts-eoa-cohort-section">EOA Cohort</p>
      <ul data-testid="cohort-students-test-scores">
        <li>
          {cohort_sat_act_score_count}% SAT/ACT superscores
          <ul className="list-type-disc">
            <li>{cohort_sat_score_count}% SAT superscores</li>
            <li>{cohort_act_score_count}% ACT superscores</li>
          </ul>
        </li>
        <li>{cohort_ap_score_count}% AP superscores</li>
        <li>{cohort_ib_score_count}% IB superscores</li>
      </ul>
    </>
  );
}

export default TestScoresContent;