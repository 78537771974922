import './PageHeader.css';

interface PageHeaderProps {
  title: string;
  pageDescription?: string;
  children?: React.ReactNode;
}

function PageHeader({ title, pageDescription, children }: PageHeaderProps) {
  return (
    <div className="page-header-container">
      <div className="page-header-content">
        <h1 className="page-header-title">{title}</h1>
        {children}
      </div>
      { pageDescription ? <p data-testid="page-description">{pageDescription}</p> : null }
    </div>
  );
}

export default PageHeader;