import { useMemo } from 'react';
import useFetchData from '../hooks/useFetchData';
import useSchools from '../hooks/useSchools';
import { getTranscriptDetails } from '../../services/transcriptsService';
import { TranscriptDetails } from '../../interfaces/TranscriptsInterface';
import SecondaryButton from '../reusable/SecondaryButton';
import Loader from '../reusable/Loader';

import './TranscriptDetailsMatrix.css';

interface ColumnsData {
  columnName: string;
  columnCredits: number;
}

const getMatrixColumnsData = (data: TranscriptDetails) => {
  const columnsData: ColumnsData[] = [];

  data.academic_periods[0].terms[0].subject_areas.forEach(subjectArea => {
    columnsData.push({ columnName: subjectArea.name, columnCredits: subjectArea.num_credits });
  });

  return columnsData;
};

const getCreditsByMatrixRow = (data: TranscriptDetails) => {
  const rowsData: { [key: string]: number } = {};

  data.academic_periods.forEach((academicPeriod) => {
    let rowCredits = 0;
    academicPeriod.terms.forEach(term => {
      term.subject_areas.forEach(subjectArea => {
        subjectArea.courses.forEach(course => {
          rowCredits += course.num_credits;
        });
      });
    });

    rowsData[academicPeriod.period] = rowCredits;
  });

  return rowsData;
};

interface TranscriptDetailsMatrixProps {
  transcriptId: string;
}

function TranscriptDetailsMatrix({ transcriptId }: TranscriptDetailsMatrixProps) {

  const { selectedSchoolFromSideNav } = useSchools();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId: string, access_token: string) => getTranscriptDetails(eoaId, access_token, selectedSchoolFromSideNav, transcriptId), 
    errorMessage: 'Error occured while fetching transcript detail data!',
    options: {
      dependencies: [selectedSchoolFromSideNav]
    }
  });

  const matrixColumnsData = useMemo(() => {
    if (!response) return null;
    return getMatrixColumnsData(response.data);
  }, [response]);

  const matrixRowsData = useMemo(() => {
    if (!response) return null;
    return getCreditsByMatrixRow(response.data);
  }, [response]);

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing transcript details data!</p>;

  const data = response.data;

  return (
    <>
      <div className="matrix-row">
        <div className="first-column-cell"></div>
        { matrixColumnsData && matrixColumnsData.map((columnData, index) => (
          <div className="matrix-cell" key={index}>
            <p>{columnData.columnName}</p>
            <p>{columnData.columnCredits} credits</p>
          </div>
        ))}
      </div>
      { data.academic_periods.map((academicPeriod, index) => (
        <div key={index} className="matrix-row">
          <div className="first-column-cell">
            <div className="matrix-row-name">
              <p>{academicPeriod.period}</p>
              <p>{matrixRowsData ? matrixRowsData[academicPeriod.period] : null} credits</p>
            </div>
            <div className="matrix-row-sub-sections">
              { academicPeriod.terms.map((term, index) => (
                <p key={index} className="matrix-row-sub-section">{term.name}</p>
              ))}
            </div>
          </div>
          <div className="matrix-sub-rows">
            {academicPeriod.terms.map((term, index) => (
              <div key={index} className="matrix-sub-row">
                { term.subject_areas.map((subject, index) => (
                  <div key={index} className="matrix-cell">
                    { subject.courses.map((course, index) => (
                      <div key={index}>
                        <p>Course: {course.name}</p>
                        <p>Code: {course.code}</p>
                        <p>Grade: {course.letter_grade}</p>
                        <p>Credits: {course.num_credits}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="matrix-action-button">
        <SecondaryButton buttonText="Print" buttonAction={() => console.log('print individual transcript')} />
      </div>
    </>
  );
}

export default TranscriptDetailsMatrix;