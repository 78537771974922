import { getDemographicsSummary } from '../../../services/dataQcSummaryService';
import useFetchData from '../../hooks/useFetchData';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import Loader from '../../reusable/Loader';

function DemographicsContent() {
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getDemographicsSummary(eoaId, access_token, selectedSchoolFromDataQc), 
    errorMessage: 'Error occured while fetching demographics summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  if (isPending) return <Loader />;

  if (error || !response) return <p>Missing demographics summary data!</p>;

  const demographicsSummary = response.data;

  const { student_count, demographic_percentages } = demographicsSummary.total;
  const { student_count: cohort_student_count, demographic_percentages: cohort_demographic_percentages } = demographicsSummary.cohort;
  
  const totalData = demographic_percentages.map(mapDemographicsData);
  const cohortData = cohort_demographic_percentages.map(mapDemographicsData);

  return (
    <>
      <p>{student_count} students matched to demographics and transcripts</p>
      <ul data-testid="total-students-statistics">
        <li>{totalData[0].female}% female, {totalData[0].male}% male</li>
        <li>{totalData[1].black}% Black, {totalData[1].latinX}% LatinX, {totalData[1].asian}% Asian, {totalData[1].twoOrMore}% Two or More</li>
        <li>{totalData[2].studentsWithDisabilities}% Students with Disabilities, {totalData[2].englishLanguageLearners}% English Language Learners, {totalData[2].lowIncome}% Low Income</li>
      </ul>

      <p>{cohort_student_count} students qualify for EOA cohort</p>
      <ul data-testid="cohort-students-statistics">
        <li>{cohortData[0].female}% female, {cohortData[0].male}% male</li>
        <li>{cohortData[1].black}% Black, {cohortData[1].latinX}% LatinX, {cohortData[1].asian}% Asian, {cohortData[1].twoOrMore}% Two or More</li>
        <li>{cohortData[2].studentsWithDisabilities}% Students with Disabilities, {cohortData[2].englishLanguageLearners}% English Language Learners, {cohortData[2].lowIncome}% Low Income</li>
      </ul>
    </>
  );
}

export default DemographicsContent;

const mapDemographicsData = (data: { [key: string]: string }) => ({
  female: data.female,
  male: data.male,
  black: data.Black,
  latinX: data.LatinX,
  asian: data.Asian,
  twoOrMore: data['Two or More'],
  studentsWithDisabilities: data['Students with Disabilities'],
  englishLanguageLearners: data['English Language Learners'],
  lowIncome: data['Low Income'],
});