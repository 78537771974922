import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { TableRow, styled } from '@mui/material';

interface CustomTableProps {
  children: React.ReactNode;
  tableHeaderData: string[];
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--border-color)',
    whiteSpace: 'wrap',
    fontWeight: '600'
  },
  [`&.${tableCellClasses.body}`]: {
    whiteSpace: 'wrap'
  }
}));

export default function CustomTable({tableHeaderData, children}: CustomTableProps) {

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {tableHeaderData.map((element, index) => (
              <StyledTableCell align="left" key={index}>{element}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
