import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import XqLogo from '../assets/xq-logo-white-background.svg';
import PrimaryButton from '../reusable/PrimaryButton';
import Footer from '../footer/Footer';
import { Navigate } from 'react-router-dom';
import Loader from '../reusable/Loader';

import './LoginPage.css';

function Login() {

  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const invitation = searchParams.get('invitation');
    const organization = searchParams.get('organization');
    const organization_name = searchParams.get('organization_name');

    if (!isLoading && !isAuthenticated && invitation && organization && organization_name) {
      loginWithRedirect({authorizationParams: { invitation: invitation || '', organization: organization || '', organization_name: organization_name}});
    }
  }, [isAuthenticated, isLoading]);

  if (isAuthenticated) return <Navigate to="/overview" />;

  const handleSignIn = () => {
    loginWithRedirect();
  };

  return (
    <div className="login-container">
      { isLoading ? <Loader fullScreen={true} /> : null}
      <div className="login-container-title">
        <img alt="logo" src={XqLogo} />
        <h3>Educational Opportunity Audit</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
             sed do eiusmod tempor incididunt ut labore et dolore 
             magna aliqua. Ut enim ad minim veniam, quis nostrud 
             exercitation ullamco laboris nisi ut aliquip ex ea 
             commodo consequat.</p>
      </div>
      <div className="login-box"></div>
      <PrimaryButton buttonText="Sign in" buttonAction={handleSignIn} />
      <Footer classNameProp="login-"/>
    </div>
  );
}

export default Login;