import PageHeader from './reusable/PageHeader';

function Overview() {
  return (
    <>
      <PageHeader title="Overview" />
      <p>Overview</p>
    </>
  );
}

export default Overview;