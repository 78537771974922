import { useEffect, useState } from 'react';
import { TableRow } from '@mui/material';
import CustomPagination from '../reusable/CustomPagination';
import CustomTable from '../reusable/CustomTable';
import { styled } from '@mui/material/styles';
import { TableCell, tableCellClasses } from '@mui/material';
import usePagination from '../hooks/usePagination';
import { getAuditLog } from '../../services/transcriptsService';
import { AuditLogData } from '../../interfaces/UploadedFileDataInterface';
import { AUDIT_LOG_ITEMS_PER_PAGE } from '../../constants';
import PaginationData from '../../interfaces/PaginationInterface';
import useFetchData from '../hooks/useFetchData';
import Loader from '../reusable/Loader';
import SideDrawer from '../data qc/SideDrawer';

import './AuditLogModalTable.css';

const tableHeaderData = ['Time/Date', 'User Name', 'Action', 'Name of file'];
const tableDataColumnKeys: Array<keyof AuditLogData> = ['timestamp', 'user_name', 'action', 'file_name'];

interface AudiditLogModalTableProps {
  modalType: string | null;
  handleCloseModal: () => void;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    whiteSpace: 'wrap'
  },
}));

const renderTableCellAuditLogData = (key: string, value: any) => {
  if (key === 'timestamp') {
    const date = new Date(value);
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
      month: 'long', 
      day: 'numeric', 
      year: 'numeric'
    });

    const formattedDate = dateFormatter.format(date);

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      hour12: true
    });
    const formattedTime = timeFormatter.format(date);
    return `${formattedDate} ${formattedTime}`;
  } else {
    return value;
  }
};

function AuditLogModalTable({modalType, handleCloseModal}: AudiditLogModalTableProps) {
  const [auditLogData, setAuditLogData] = useState<AuditLogData[] | null>(null);
  const [paginationData, setPaginationData] = useState<PaginationData>({ totalItems: 0, size: 0, pages: 0 });

  const {currentPage, totalItems, pages, startItem, endItem, handlePageChange} = usePagination(paginationData);

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId, access_token) => getAuditLog(eoaId, access_token, currentPage, AUDIT_LOG_ITEMS_PER_PAGE),
    errorMessage: 'Error occured while fetching audit log data!'
  });

  useEffect(() => {
    if (response) {
      const { items, total, pages } = response.data;

      setAuditLogData(items);
      setPaginationData({ totalItems: total, size: AUDIT_LOG_ITEMS_PER_PAGE, pages });
    }
  }, [response]);

  return (
    <SideDrawer 
      title="Audit log" 
      isOpen={modalType === 'audit log'} 
      handleClose={handleCloseModal} 
      classes={['audit-log-side-drawer']}
    >
      <div className="audit-log-modal-table-container">
        { isPending ? <Loader /> : null }
        { error ? <p>Missing audit log data!</p> : null }
        { auditLogData ? 
          <CustomTable tableHeaderData={tableHeaderData}>
            {auditLogData.map((item, index) => (
              <TableRow key={index}>
                {tableDataColumnKeys.map((key) => (
                  <StyledTableCell key={key} align="left">
                    {renderTableCellAuditLogData(key, item[key])}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </CustomTable>
          :
          null
        }
        <CustomPagination
          currentPage={currentPage}
          totalItems={totalItems}
          pages={pages}
          startItem={startItem}
          endItem={endItem}
          handlePageChange={() => handlePageChange} 
        />
      </div>
    </SideDrawer>
  );
}

export default AuditLogModalTable;